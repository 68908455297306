import moment, { Moment } from 'moment';

import { SelectedTime } from '@/context/types';

const MAX_SECONDS_COUNT = 59;

export const getBookingItemName = (areMeetingRoomsSelected: boolean) =>
	areMeetingRoomsSelected ? 'room' : 'desk';

export const isMeetingRoomTimeInPast = (
	timelineDate: Moment,
	selectedTime: SelectedTime,
	isAllDayChecked: boolean
) => {
	const currentTime = timelineDate;

	if (isAllDayChecked) {
		return currentTime.isSameOrBefore(moment(), 'day');
	}

	const { from } = selectedTime;
	currentTime.set({
		hour: from.hour(),
		minute: from.minute(),
		second: MAX_SECONDS_COUNT,
	});

	return currentTime.isBefore(moment());
};
