import { LocationTypeId } from '@/types/Configuration';

export interface OfficeSelectorOption {
	name: string;
	officeLocation: LocationTypeId;
}

export enum BookingTabsIndexes {
	BookingForm = 0,
	Workplace = 1,
}

export enum BookingOfficesId {
	Minsk = '1',
	Warsaw = '3',
}
