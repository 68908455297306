import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

import { CELL_HEIGHT } from '../../constants';

import { BorderRadiuses } from '@/constants/borderRadiuses';

export const TimelineSectionWrapper = styled('div')<{
	isSelected: boolean;
	isDisabled: boolean;
}>(({ theme, isSelected, isDisabled }) => ({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	cursor: isDisabled ? 'default' : 'pointer',
	height: CELL_HEIGHT,
	backgroundColor: isSelected ? theme.palette['accent-1'] : 'transparent',
	borderTop: `1px solid ${theme.palette['primary-3']}`,
	borderBottomLeftRadius: BorderRadiuses.xs2,
	paddingLeft: theme.spaces.s,
	paddingRight: theme.spaces.s,
}));

export const TimelineSectionSkeleton = styled(Skeleton)({
	height: CELL_HEIGHT,
	width: '100%',
});
