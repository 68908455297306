import styled from '@emotion/styled';
import { Sidebar } from '@mms/mms-ui-library';

import { closedDrawerWidth } from '../config';

export const SidebarWrapper = styled(Sidebar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, isOpen }) => ({
	marginTop: 54,

	[theme.breakpoints.down(theme.breakpoints.desktopXS)]: {
		left: isOpen ? 0 : -closedDrawerWidth,
	},
}));
