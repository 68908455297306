import { useTheme } from '@emotion/react';
import {
	BookingCalendarIcon,
	ClockIcon,
	CrossIcon,
	MapPinIcon,
} from '@mms/mms-ui-library';
import { memo, useMemo } from 'react';

import { getFormattedTimePeriod } from '@/helpers/bookingFormatters';
import { getFullName, createPeriodInText } from '@/helpers/index';

import {
	ListItemContent,
	BookingListItem,
	IconCloseWrapper,
	BookingListTypography,
	Divider,
	IconWrapper,
	ItemRowWithIcon,
	IconButton,
} from './styled';
import type { RoomBookingListItemProps } from './types';

function ItemInner({
	weeksInInterval,
	dateList,
	handleDelete,
	meetingRoomName,
	name,
	lastName,
	fromTime,
	toTime,
}: RoomBookingListItemProps) {
	const theme = useTheme();

	const bookingTime = useMemo(
		() => getFormattedTimePeriod(fromTime, toTime, true),
		[fromTime, toTime]
	);

	return (
		<>
			<BookingListItem type="meetingRoom">
				<ListItemContent>
					{lastName && name && (
						<BookingListTypography
							Component="p"
							variant="s-600"
							color={theme.palette['base-6']}
						>
							{getFullName(name, lastName)}
						</BookingListTypography>
					)}
					<ItemRowWithIcon>
						<IconWrapper>
							<BookingCalendarIcon />
						</IconWrapper>
						{createPeriodInText(weeksInInterval, dateList)
							?.split('\n')
							.map((line, index) => (
								<BookingListTypography
									key={index}
									Component="p"
									variant="s-400"
									color={theme.palette['base-6']}
								>
									{line}
								</BookingListTypography>
							))}
					</ItemRowWithIcon>
					<ItemRowWithIcon>
						<IconWrapper>
							<MapPinIcon />
						</IconWrapper>
						<BookingListTypography
							Component="p"
							variant="s-400"
							color={theme.palette['base-6']}
						>
							{meetingRoomName}
						</BookingListTypography>
					</ItemRowWithIcon>
					<ItemRowWithIcon>
						<IconWrapper>
							<ClockIcon />
						</IconWrapper>
						<BookingListTypography
							Component="p"
							variant="s-400"
							color={theme.palette['base-6']}
						>
							{bookingTime}
						</BookingListTypography>
					</ItemRowWithIcon>
				</ListItemContent>
				<IconCloseWrapper>
					<IconButton onClick={handleDelete}>
						<CrossIcon />
					</IconButton>
				</IconCloseWrapper>
			</BookingListItem>
			<Divider />
		</>
	);
}

const areItemPropsEqual = (
	prevProps: { userId: number },
	nextProps: { userId: number }
) => prevProps.userId === nextProps.userId;

export const RoomBookingListItem = memo(ItemInner, areItemPropsEqual);
