import {
	createContext,
	useMemo,
	useContext,
	useState,
	PropsWithChildren,
} from 'react';

import { BookingMapContextValues } from '../../../context/types';
import { MapPopUp } from '../components/MapPopUp';

const BookingMapContext = createContext({} as BookingMapContextValues);

export function BookingMapProvider({ children }: PropsWithChildren) {
	const [isMapOpen, setIsMapOpen] = useState(false);

	const value: BookingMapContextValues = useMemo(
		() => ({
			isMapOpen,
			setIsMapOpen,
		}),
		[isMapOpen]
	);

	return (
		<BookingMapContext.Provider value={value}>
			{children}
			{isMapOpen && <MapPopUp />}
		</BookingMapContext.Provider>
	);
}

export const useBookingMapContext = () => useContext(BookingMapContext);
