import { Typography } from '@mms/mms-ui-library';

import { PopUpBody } from '../PopUpBody';

import { useAuth } from '@/context/AuthContext';
import { createPeriodInText, getDeleteDialogText } from '@/helpers/booking';
import { getFormattedTimePeriod } from '@/helpers/bookingFormatters';
import { getDialogTextComponent } from '@/helpers/getDeleteDialogTextComponent';
import { MeetingRoomBooking } from '@/types/Booking';

interface DeleteBookingPopupProps {
	bookingItem: MeetingRoomBooking;
}

export function DeleteMeetingRoomBookingPopupBody({
	bookingItem,
}: DeleteBookingPopupProps) {
	const {
		weeksInInterval,
		dateList,
		name,
		lastName,
		meetingRoomName,
		fromTime,
		toTime,
		userId = 0,
	} = bookingItem;
	const { id } = useAuth();

	const deskLikeBookingItem = {
		isBlock: false,
		userId,
		firstName: name,
		lastName,
	};

	return (
		<PopUpBody>
			<Typography variant="m-400" align="center">
				{getDeleteDialogText(deskLikeBookingItem, id)}
			</Typography>
			<Typography variant="m-400" align="center">
				{getDialogTextComponent(deskLikeBookingItem, id)}
			</Typography>
			<Typography variant="m-600" align="center">
				{meetingRoomName}
			</Typography>
			<Typography variant="m-600" align="center">
				{createPeriodInText(weeksInInterval, dateList)}
			</Typography>
			<Typography variant="m-600" align="center">
				{getFormattedTimePeriod(fromTime, toTime, false)}
			</Typography>
		</PopUpBody>
	);
}
