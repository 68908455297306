import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import {
	GET_BOOKING_API_ENDPOINT,
	BookingQueriesKeys,
} from '@/constants/index';

import { GetBookingResponse } from './types';

interface UseGetBookingsOptions {
	officeId: string;
	userId: number;
	includeEmployee: boolean;
}

export const useGetBookings = (
	{ officeId, userId, includeEmployee }: UseGetBookingsOptions,
	options?: UseQueryOptions<GetBookingResponse, AxiosError>
) =>
	useQuery<GetBookingResponse, AxiosError>({
		queryKey: [BookingQueriesKeys.bookings, userId, includeEmployee, officeId],
		queryFn: async () => {
			try {
				const { data } = await axios.get<GetBookingResponse>(
					GET_BOOKING_API_ENDPOINT,
					{
						params: {
							userId,
							includeEmployee,
							officeId,
						},
					}
				);

				return data;
			} catch (error) {
				throw error as AxiosError;
			}
		},
		keepPreviousData: true,
		enabled: Boolean(userId),
		refetchOnWindowFocus: false,
		...options,
	});
