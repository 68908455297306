import axios, { AxiosError, isAxiosError } from 'axios';
import { Moment } from 'moment';
import { UseMutationOptions, useMutation } from 'react-query';

import { ServerResponseError } from '../types';

import { GET_BOOKING_API_ENDPOINT } from '@/constants/index';
import { getDateAsResponseType } from '@/helpers/booking';

import { CreateMutationVariables } from './types';
import { getRequestType } from './utils';

export const useCreateBooking = (
	options: UseMutationOptions<
		void,
		AxiosError<ServerResponseError>,
		CreateMutationVariables
	>
) =>
	useMutation<void, AxiosError<ServerResponseError>, CreateMutationVariables>({
		...options,
		mutationFn: ({
			requestType,
			workplaceId,
			fromDate,
			toDate,
			weeksInterval,
			officeId,
			userId = null,
		}) =>
			axios
				.post(GET_BOOKING_API_ENDPOINT, {
					requestType: getRequestType(requestType, userId),
					workplaceId,
					fromDate: getDateAsResponseType(fromDate as Moment),
					toDate: getDateAsResponseType(toDate as Moment),
					weeksInInterval: weeksInterval,
					userId,
					officeId,
				})
				.then((response) => response.data)
				.catch((error) => {
					if (isAxiosError(error)) {
						throw error;
					}
				}),
	});
