import moment from 'moment';
import { useLayoutEffect } from 'react';

import { Timeline } from '@/components/Timeline';
import { Section } from '@/components/Timeline/types';
import { useBookingContext } from '@/context/Booking';
import { SelectedBookingItemType } from '@/context/types';
import { getDateAsResponseType } from '@/helpers/booking';
import { getInitialTime, getInitialTimelineDate } from '@/helpers/bookingTime';
import { useGetMeetingRoomReservations } from '@/queries/booking/useGetMeetingRoomsReservation copy';

import { MeetingRoomsRendererWrapper } from './styled';
import { getRelevantTimeForWorkingHours, getSelectedPeriod } from './utils';

const TIMELINE_SCROLL_PROPS = {
	scrollToCurrentTime: { behavior: 'smooth', position: 'center' },
	scrollToSelectedPeriod: { behavior: 'auto' },
} as const;

export function MeetingRoomsRenderer() {
	const {
		selectedTime,
		timelineDate,
		isWorkingHours,
		isAllDayChecked,
		selectedItem,
		meetingRoomCollisions,
		setTimelineDate,
		setIsWorkingHours,
		setSelectedTime,
		setSelectedItem,
		setFirstDay,
		setLastDay,
		isTimelineInRange,
		setInitialTimelineDate,
	} = useBookingContext();

	const handleWorkingHoursChange = (areHoursWorking: boolean) => {
		setIsWorkingHours(areHoursWorking);

		if (areHoursWorking) {
			const relevantTime = getRelevantTimeForWorkingHours(
				selectedTime,
				areHoursWorking
			);

			if (relevantTime) {
				setSelectedTime(relevantTime);
			}
		}
	};

	useLayoutEffect(() => {
		setInitialTimelineDate(getInitialTimelineDate(isWorkingHours));

		if (!selectedTime) {
			setSelectedTime(getInitialTime(isWorkingHours));
		}
	}, []);

	const { data, isLoading, remove } = useGetMeetingRoomReservations(
		getDateAsResponseType(timelineDate)
	);

	const handleTimePeriodSelection = (
		section: Section,
		from: Date,
		to: Date
	) => {
		setSelectedItem({
			type: SelectedBookingItemType.Room,
			...section,
		});

		if (!isAllDayChecked) {
			setSelectedTime({
				from: moment(from),
				to: moment(to),
			});
		}

		setFirstDay(moment(timelineDate));
		setLastDay(moment(timelineDate));
	};

	const selectedTimePeriod = getSelectedPeriod(
		selectedItem,
		selectedTime,
		isTimelineInRange,
		meetingRoomCollisions.length > 0,
		isAllDayChecked
	);

	const handleForwardClick = () => {
		setTimelineDate(timelineDate.clone().add(1, 'day'));
		remove();
	};

	const handleBackClick = () => {
		setTimelineDate(timelineDate.clone().subtract(1, 'day'));
		remove();
	};

	const handleTodayClick = () => {
		if (!timelineDate.isSame(moment(), 'day')) {
			setTimelineDate(moment());
			remove();
		}
	};

	const isDisabled = timelineDate
		.startOf('day')
		.isBefore(moment().startOf('day'));

	const handleSectionSelection = (section: Section) => {
		if (selectedItem && selectedItem.id === section.id) {
			setSelectedItem(null);

			return;
		}

		setSelectedItem({
			type: SelectedBookingItemType.Room,
			...section,
		});
	};

	return (
		<MeetingRoomsRendererWrapper>
			<Timeline
				{...TIMELINE_SCROLL_PROPS}
				selectedDate={timelineDate.toDate()}
				data={data || []}
				showLoader={isLoading}
				selectedTimePeriod={selectedTimePeriod}
				isWorkingHoursOnly={isWorkingHours}
				onIsWorkingHoursOnlyChange={handleWorkingHoursChange}
				onTimePeriodSelection={handleTimePeriodSelection}
				onGoForwardDate={handleForwardClick}
				onGoBackwardDate={handleBackClick}
				onGoTodayDate={handleTodayClick}
				selectFromPeriodStart
				isPeriodSelectionDisabled={isDisabled}
				selectedSectionId={selectedItem?.id}
				onSectionSelection={handleSectionSelection}
			/>
		</MeetingRoomsRendererWrapper>
	);
}
