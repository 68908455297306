import { useTheme } from '@emotion/react';
import { Typography } from '@mms/mms-ui-library';

import { TimelineCurrentDateWrapper } from './styles';

export function CurrentDate({ selectedDate }: { selectedDate: Date }) {
	const theme = useTheme();

	return (
		<TimelineCurrentDateWrapper>
			<Typography color={theme.palette['accent-6']} variant="s-600">
				{selectedDate.getDate().toString().padStart(2, '0')}
			</Typography>
			<Typography color={theme.palette['accent-6']} variant="s-400">
				{selectedDate.toLocaleDateString('en-EN', { weekday: 'long' })}
			</Typography>
		</TimelineCurrentDateWrapper>
	);
}
