import { Typography } from '@mms/mms-ui-library';
import { useMemo } from 'react';

import {
	ALL_HOURS_CELLS_AMOUNT,
	ALL_HOURS_START,
	BUSINESS_HOURS_CELLS_AMOUNT,
	BUSSINESS_HOURS_START,
} from '../../constants';
import { useTimelineContext } from '../../TimelineContext';
import { generateTimeLabels } from '../../utils/generateTimeLabels';

import { TimeLabelsWrapper } from './styles';

export function TimeLabels() {
	const { isWorkingHoursOnly } = useTimelineContext();

	const timeLabels = useMemo(
		() =>
			generateTimeLabels(
				isWorkingHoursOnly ? BUSSINESS_HOURS_START : ALL_HOURS_START,
				isWorkingHoursOnly
					? BUSINESS_HOURS_CELLS_AMOUNT
					: ALL_HOURS_CELLS_AMOUNT
			),
		[isWorkingHoursOnly]
	);

	return (
		<TimeLabelsWrapper>
			{timeLabels.map((time) => (
				<Typography key={time} variant="xs-400">
					{time}
				</Typography>
			))}
		</TimeLabelsWrapper>
	);
}
