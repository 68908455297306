import styled from '@emotion/styled';
import { CalendarUI } from '@mms/mms-ui-library';

import { BorderRadiuses } from '@/constants/borderRadiuses';

import { DAY_CELL_SIZE } from './constants';
import { COLLISION_BADGE_CLASS_NAME } from './Day/constants';

const CALENDAR_WIDTH = 213;
const CALENDAR_HEIGHT = 234;
const CALENDAR_CONTENT_HEIGHT = 189;
const CALENDAR_PADDING = 12;
const BEFORE_RIGHT_MARGIN = 6;

const DAY_LABEL_HEIGHT = 30;
const DAY_LABEL_WIDTH = 27;
const WEEK_CONTAINER_BOTTOM_PADDING = 3;

const CALENDAR_HEADER_HEIGHT = 18;

const PSEUDO_ELEMENT_HEIGHT = 16;
const PSEUDO_ELEMENT_RIGHT = -2;

const COLLISION_COLOR = '#DB0000';

export const CustomCalendarUI = styled(CalendarUI)(({ theme }) => ({
	width: CALENDAR_WIDTH,
	height: CALENDAR_HEIGHT,
	padding: CALENDAR_PADDING,
	flexShrink: 0,

	'.MuiPickersCalendarHeader-root': {
		padding: 0,
		height: CALENDAR_HEADER_HEIGHT,
		minHeight: CALENDAR_HEADER_HEIGHT,
	},

	'.MuiPickersCalendarHeader-labelContainer:before': {
		marginRight: BEFORE_RIGHT_MARGIN,
	},

	'.MuiPickersCalendarHeader-label': {
		...theme.typography.body['xs-700'],
	},

	'.MuiPickersArrowSwitcher-button': {
		display: 'none',
	},

	'.MuiPickersFadeTransitionGroup-root': {
		padding: 0,
	},

	'.MuiDateCalendar-viewTransitionContainer': {
		height: CALENDAR_CONTENT_HEIGHT,
	},

	'.MuiDayCalendar-weekDayLabel': {
		height: DAY_LABEL_HEIGHT,
		width: DAY_LABEL_WIDTH,
		flexShrink: 0,
		...theme.typography.body['xs-500'],
		margin: 0,
	},

	'& .MuiDayCalendar-weekContainer:not(:last-child)': {
		paddingBottom: WEEK_CONTAINER_BOTTOM_PADDING,
	},

	'.MuiPickersDay-today:not(.Mui-selected)': {
		color: `${theme.palette['base-6']} !important`,
		border: `1px solid ${theme.palette['accent-6']} !important`,
		background: `${theme.palette['accent-1']} !important`,
	},

	'#rangeDays': {
		backgroundColor: `${theme.palette['accent-5']} !important`,
		borderRadius: BorderRadiuses.half,
		border: 'unset !important',
		opacity: `1 !important`,
		color: `${theme.palette['primary-1']} !important`,

		'&::before': {
			height: PSEUDO_ELEMENT_HEIGHT,
			right: PSEUDO_ELEMENT_RIGHT,
		},

		'&::after': {
			height: PSEUDO_ELEMENT_HEIGHT,
			right: PSEUDO_ELEMENT_RIGHT,
		},
	},

	'& .MuiDayCalendar-slideTransition': {
		boxSizing: 'content-box',
		overflow: 'visible',
		minHeight: 0,
	},

	'& .MuiDayCalendar-monthContainer': {
		overflow: 'visible',
	},

	'.MuiBadge-root': {
		boxSizing: 'unset',
		display: 'flex',
		justifyContent: 'center',
		width: `${DAY_CELL_SIZE}px`,
		height: `${DAY_CELL_SIZE}px`,
		lineHeight: 0,

		button: {
			height: '100%',
			width: '100%',
			...theme.typography.body['xs-400'],
		},

		'& .MuiPickersDay-root': {
			margin: 0,
		},
	},

	'.MuiBadge-badge': {
		background: theme.palette['accent-6'],
		border: `1px solid ${theme.palette['primary-1']}`,
		right: '21%',
	},
	[`.${COLLISION_BADGE_CLASS_NAME} .MuiBadge-badge`]: {
		background: COLLISION_COLOR,
	},

	'.MuiDayCalendar-weekContainer': {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		boxSizing: 'border-box',
	},
}));
