import { CELL_WIDTH } from '../constants';

export const calculateSelectedRangeStyle = (from: number, to: number) => {
	const posX = from * CELL_WIDTH;
	const width = (to - from) * CELL_WIDTH;

	return {
		left: posX,
		width,
	};
};
