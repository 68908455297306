export const BOOKED_LABEL_HEIGHT = 48;

export const FULL_DAY_PERIOD_TEXT = 'All day';

export const LOOP_ICON_TRANSLATION = 'translate(-2px,2px)';
export const QUATER_PERIOD_LOOP_ICON_TRANSLATION = 'translate(4px,4px)';

export const QUATER_PERIOD_PADDING = '0 2px 2px 0';
export const BOOKED_TIME_PERIOD_GAP = 3.9;

export const BOOKED_TYPOGRAPHY_FONT_WEIGHT = 600;
