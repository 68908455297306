import { MouseEvent } from 'react';

import { ALL_HOURS_CELLS_AMOUNT, CELL_WIDTH } from '../constants';
import { Section, SelectTimePeriod } from '../types';

const PERIODS_IN_CELL = 4;

export const usePeriodSelection = (
	section: Section,
	selectionPeriod: number,
	selectFromPeriodStart: boolean,
	onPeriodSelect: SelectTimePeriod<number>,
	isPeriodSelectionDisabled: boolean
) => {
	const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
		if (!isPeriodSelectionDisabled) {
			e.preventDefault();
			const offsetX = e.pageX - e.currentTarget.getBoundingClientRect().left;

			const cellIndex = selectFromPeriodStart
				? Math.floor(offsetX / CELL_WIDTH)
				: Math.floor((offsetX / CELL_WIDTH) * PERIODS_IN_CELL) /
				  PERIODS_IN_CELL;

			onPeriodSelect(
				section,
				cellIndex,
				Math.min(cellIndex + selectionPeriod, ALL_HOURS_CELLS_AMOUNT)
			);
		}
	};

	return {
		handleMouseDown,
	};
};
