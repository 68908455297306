import moment, { Moment } from 'moment';

import { SelectedTimePeriod } from '@/components/Timeline/types';
import {
	TIME_SELECTOR_STEP_IN_MINUTES,
	VIEW_TIME_FORMAT,
} from '@/constants/booking';
import {
	SelectedBookingItem,
	SelectedBookingItemType,
	SelectedTime,
} from '@/context/types';
import { getFormattedTime } from '@/helpers/bookingFormatters';
import {
	getDayEndHour,
	getDayStartHour,
	getNearestQuarterHourTime,
	getTimeRange,
} from '@/helpers/bookingTime';

const MINUTES_IN_HOUR = 60;

export const getSelectedPeriod = (
	selectedItem: SelectedBookingItem | null,
	selectedTime: SelectedTime | null,
	isTimelineInRange: boolean,
	isOverlaped: boolean,
	isAllDayChecked: boolean
): SelectedTimePeriod<Date> | null => {
	if (
		!isTimelineInRange ||
		selectedItem?.type === SelectedBookingItemType.Desk
	) {
		return null;
	}

	if (isAllDayChecked && selectedItem) {
		return {
			from: moment().startOf('day').toDate(),
			to: moment().add(1, 'day').startOf('day').toDate(),
			isOverlaped,
		};
	}

	if (!selectedItem || !selectedTime) {
		return null;
	}

	const [first, last] = getTimeRange(selectedTime.from, selectedTime.to);

	return {
		from: first.toDate(),
		to: last.toDate(),
		isOverlaped,
	};
};

const ZERO_TIME = getFormattedTime(0, 0);

export const getRelevantTimeForWorkingHours = (
	selectedTime: SelectedTime | null,
	areHoursWorking: boolean
): SelectedTime | null => {
	if (!selectedTime) {
		return null;
	}

	const startHour = getDayStartHour(areHoursWorking);
	const endHour = getDayEndHour(areHoursWorking);

	const startFromTime = getFormattedTime(startHour, 0);
	const endFromTime = getFormattedTime(
		endHour,
		MINUTES_IN_HOUR - TIME_SELECTOR_STEP_IN_MINUTES
	);

	const startToTime = getFormattedTime(
		startHour,
		TIME_SELECTOR_STEP_IN_MINUTES
	);
	const endToTime = getFormattedTime(endHour + 1, 0);

	const currentFromTime = getNearestQuarterHourTime(
		selectedTime.from.toDate(),
		undefined
	);
	const currentToTime = getNearestQuarterHourTime(
		selectedTime.to.toDate(),
		undefined
	);

	const newSelectedTime: { from?: Moment; to?: Moment } = {};

	if (currentFromTime === ZERO_TIME || currentFromTime < startFromTime) {
		newSelectedTime.from = moment(startFromTime, VIEW_TIME_FORMAT);
	}

	if (currentFromTime > endFromTime) {
		newSelectedTime.from = moment(endFromTime, VIEW_TIME_FORMAT);
	}

	if (currentToTime < startToTime) {
		newSelectedTime.to = moment(startToTime, VIEW_TIME_FORMAT);
	}

	if (currentToTime === ZERO_TIME || currentToTime > endToTime) {
		newSelectedTime.to = moment(endToTime, VIEW_TIME_FORMAT);
	}

	return Object.keys(newSelectedTime).length
		? { ...selectedTime, ...newSelectedTime }
		: null;
};
