import { useTheme } from '@emotion/react';
import {
	BookingCalendarIcon,
	CrossIcon,
	MapPinIcon,
} from '@mms/mms-ui-library';
import moment from 'moment';
import { memo } from 'react';

import { BOOKING_ITEM_TIME_FORMAT_WITH_COMMA } from '../constants';

import { isNotChiefRole } from '@/constants/roles';
import {
	getBookingPlaceType,
	getFullName,
	createPeriodInText,
} from '@/helpers/index';
import { getSpaceAbriviation } from '@/pages/Booking/utils';

import {
	ListItemContent,
	BookingListItem,
	IconCloseWrapper,
	BookingListTypography,
	Divider,
	IconWrapper,
	ItemRowWithIcon,
	IconButton,
} from './styled';
import type { ItemInnerProps } from './types';

function ItemInner({
	currentUserId,
	userRole,
	userId,
	isBlock,
	lastName,
	firstName,
	weeksInInterval,
	dateList,
	workplaceNumber,
	handleDelete,
	office,
	workspaceId,
}: ItemInnerProps) {
	const theme = useTheme();

	return (
		<>
			<BookingListItem
				type={getBookingPlaceType(currentUserId, userId, isBlock)}
			>
				<ListItemContent>
					{lastName && firstName && (
						<BookingListTypography
							Component="p"
							variant="s-600"
							color={theme.palette['base-6']}
						>
							{getFullName(firstName, lastName)}
						</BookingListTypography>
					)}
					<ItemRowWithIcon>
						<IconWrapper>
							<BookingCalendarIcon />
						</IconWrapper>
						{isBlock ? (
							<BookingListTypography
								Component="p"
								variant="s-400"
								color={theme.palette['base-6']}
							>
								From{' '}
								{moment(dateList[0]).format(
									BOOKING_ITEM_TIME_FORMAT_WITH_COMMA
								)}
							</BookingListTypography>
						) : (
							createPeriodInText(weeksInInterval, dateList)
								?.split('\n')
								.map((line, index) => (
									<BookingListTypography
										key={index}
										Component="p"
										variant="s-400"
										color={theme.palette['base-6']}
									>
										{line}
									</BookingListTypography>
								))
						)}
					</ItemRowWithIcon>
					<ItemRowWithIcon>
						<IconWrapper>
							<MapPinIcon />
						</IconWrapper>
						<BookingListTypography
							Component="p"
							variant="s-400"
							color={theme.palette['base-6']}
						>
							{`${getSpaceAbriviation(
								office.id,
								workspaceId
							)}-${workplaceNumber}`}
						</BookingListTypography>
					</ItemRowWithIcon>
				</ListItemContent>
				<IconCloseWrapper>
					{!(isBlock && isNotChiefRole(userRole)) && (
						<IconButton onClick={handleDelete}>
							<CrossIcon />
						</IconButton>
					)}
				</IconCloseWrapper>
			</BookingListItem>
			<Divider />
		</>
	);
}

const areItemPropsEqual = (
	prevProps: { userId: number },
	nextProps: { userId: number }
) => prevProps.userId === nextProps.userId;

export const DeskBookingListItem = memo(ItemInner, areItemPropsEqual);
