import { MapPinIcon, Typography } from '@mms/mms-ui-library';

import { SelectedBookingItem, SelectedBookingItemType } from '@/context/types';

import { SelectedSeatViewWrapper } from './styled';

interface SelectedSeatViewProps {
	item: SelectedBookingItem;
}

export function SelectedItemView({ item }: SelectedSeatViewProps) {
	return (
		<SelectedSeatViewWrapper>
			<MapPinIcon />
			<span>
				<Typography variant="s-600">
					{item.type === SelectedBookingItemType.Desk ? 'Desk: ' : 'Room: '}
				</Typography>
				<Typography variant="m-400">
					{item.type === SelectedBookingItemType.Desk
						? `${item.abriviation}-${item.displayNumber}`
						: item.name}
				</Typography>
			</span>
		</SelectedSeatViewWrapper>
	);
}
