import { FIELD_IS_REQUIRED } from '@/constants/errors';
import { INVALID_DATE_RANGE } from '@/constants/notifications';

import { DatePickerError } from './constants';

export const getDatePickerErrorMessage = (error: string | null) => {
	switch (error) {
		case DatePickerError.InvalidDate:
			return FIELD_IS_REQUIRED;
		case DatePickerError.PastDate:
		case DatePickerError.MinDate:
		case DatePickerError.MaxDate:
			return INVALID_DATE_RANGE;
		default:
			return undefined;
	}
};
