import axios from 'axios';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_MEETING_ROOMS_RESERVATIONS_API_ENDPOINT,
	BookingQueriesKeys,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { useToast } from '@/context/index';
import { createToast } from '@/helpers/createToast';

import { GetMeetingRoomsReservationsResponse } from './types';

export const useGetMeetingRoomReservations = (date: string) => {
	const toast = useToast();

	return useQuery({
		queryKey: [BookingQueriesKeys.meetingRoomReservations, date],
		queryFn: async () => {
			try {
				if (date) {
					const response = axios.get<GetMeetingRoomsReservationsResponse>(
						GET_MEETING_ROOMS_RESERVATIONS_API_ENDPOINT,
						{
							params: {
								date,
							},
						}
					);

					return (await response).data;
				}

				return undefined;
			} catch {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return undefined;
			}
		},
		keepPreviousData: false,
		enabled: Boolean(date),
		refetchOnWindowFocus: false,
	});
};
