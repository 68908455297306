import moment from 'moment';
import {
	createContext,
	useReducer,
	useMemo,
	useCallback,
	useEffect,
	useContext,
	ComponentType,
} from 'react';

import { getInitialBookingOfficeByUserLocation } from '@/helpers/getInitialBookingOfficeByUserLocation';
import { getFullName } from '@/helpers/index';
import {
	DEFAULT_BOOKING_OFFICE,
	DEFAULT_EMPLOYEE,
} from '@/pages/Booking/constants';
import { areMeetingRoomsOpen } from '@/pages/Booking/utils';
import { MeetingRoomsCollision } from '@/queries/booking/types';
import { Workplace } from '@/types/Booking';
import { OfficeType } from '@/types/Configuration';

import { useAuth } from './AuthContext';
import { useConfiguration } from './Configuration';
import {
	BookingProviderProps,
	type Action,
	type BookingContextInitialValues,
	type BookingContextValues,
	type Payload,
	Employee,
	HighlightedDays,
	SelectedTime,
	SelectedBookingItem,
} from './types';

const BookingContext = createContext({} as BookingContextValues);

const initialState: BookingContextInitialValues = {
	meetingRoomCollisions: [],
	timelineDate: moment().startOf('day'),
	isAllDayChecked: false,
	isWorkingHours: true,
	selectedTime: null,
	selectedItem: null,
	selectedSpaceId: null,
	firstDay: moment().startOf('day'),
	lastDay: moment().startOf('day'),
	office: DEFAULT_BOOKING_OFFICE,
	initialOffice: null,
	employee: null,
	initialEmployee: DEFAULT_EMPLOYEE,
	error: null,
	highlightedDays: {
		bookedList: [],
		yourList: [],
		blockStartDate: null,
	},
	workplaces: [],
	weeksCount: 0,
	hasWorkplaceCollision: undefined,
};

const {
	SET_SELECTED_ITEM,
	SET_FIRST_DAY,
	SET_LAST_DAY,
	SET_EMPLOYEE,
	SET_OFFICE,
	SET_INITIAL_OFFICE,
	SET_HIGHLIGHTED_DAYS,
	SET_WORKPLACES,
	SET_ERROR,
	RESET_ON_OFFICE_CHANGE,
	INIT,
	SET_WEEKS_COUNT,
	SET_SELECTED_SPACE_ID,
	SET_SELECTED_TIME,
	SET_IS_WORKING_HOURS,
	SET_ALL_DAY_CHECKED,
	SET_TIMELINE_DATE,
	RESET_ON_MEETING_ROOMS_TAB_CHANGE,
	SET_MEETING_ROOM_COLLISIONS,
	SET_HAS_WORKPLACE_COLLISION,
	SET_INITIAL_TIMELINE_DATE,
} = {
	SET_TIMELINE_DATE: 'SET_TIMELINE_DATE',
	SET_ALL_DAY_CHECKED: 'SET_ALL_DAY_CHECKED',
	SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',
	SET_IS_WORKING_HOURS: 'SET_IS_WORKING_HOURS',
	SET_SELECTED_SPACE_ID: 'SET_SELECTED_SPACE_ID',
	SET_FIRST_DAY: 'SET_FIRST_DAY',
	SET_SELECTED_TIME: 'SET_SELECTED_TIME',
	SET_LAST_DAY: 'SET_LAST_DAY',
	SET_EMPLOYEE: 'SET_EMPLOYEE',
	SET_OFFICE: 'SET_OFFICE',
	SET_INITIAL_OFFICE: 'SET_INITIAL_OFFICE',
	SET_HIGHLIGHTED_DAYS: 'SET_HIGHLIGHTED_DAYS',
	SET_WORKPLACES: 'SET_WORKPLACES',
	SET_ERROR: 'SET_ERROR',
	RESET_ON_OFFICE_CHANGE: 'RESET_ON_OFFICE_CHANGE',
	RESET_ON_MEETING_ROOMS_TAB_CHANGE: 'RESET_ON_MEETING_ROOM_TAB_CHANGE',
	INIT: 'INIT',
	SET_WEEKS_COUNT: 'SET_WEEKS_COUNT',
	SET_MEETING_ROOM_COLLISIONS: 'SET_MEETING_ROOM_COLLISIONS',
	SET_HAS_WORKPLACE_COLLISION: 'SET_HAS_WORKPLACE_COLLISION',
	SET_INITIAL_TIMELINE_DATE: 'SET_INITIAL_TIMELINE_DATE',
};

const reducer = (
	state: BookingContextInitialValues,
	{ type, payload }: Action
): BookingContextInitialValues => {
	switch (type) {
		case SET_IS_WORKING_HOURS:
			return { ...state, isWorkingHours: payload as boolean };
		case SET_ALL_DAY_CHECKED:
			return { ...state, isAllDayChecked: payload as boolean };
		case SET_SELECTED_ITEM:
			return { ...state, selectedItem: payload as SelectedBookingItem | null };
		case SET_SELECTED_SPACE_ID:
			return { ...state, selectedSpaceId: payload as number | null };
		case SET_FIRST_DAY:
			return { ...state, firstDay: payload as unknown as moment.Moment };
		case SET_TIMELINE_DATE:
			return { ...state, timelineDate: payload as unknown as moment.Moment };
		case SET_INITIAL_TIMELINE_DATE:
			return {
				...state,
				timelineDate: payload as unknown as moment.Moment,
				firstDay: payload as unknown as moment.Moment,
				lastDay: payload as unknown as moment.Moment,
			};
		case SET_LAST_DAY:
			return { ...state, lastDay: payload as unknown as moment.Moment };
		case SET_SELECTED_TIME: {
			return {
				...state,
				selectedTime: payload as unknown as SelectedTime | null,
			};
		}
		case SET_EMPLOYEE:
			return { ...state, employee: payload as Employee | null };
		case SET_OFFICE:
			return { ...state, office: payload as OfficeType };
		case SET_INITIAL_OFFICE:
			return { ...state, initialOffice: payload as OfficeType };
		case SET_MEETING_ROOM_COLLISIONS:
			return {
				...state,
				meetingRoomCollisions: payload as Array<MeetingRoomsCollision>,
			};
		case RESET_ON_OFFICE_CHANGE:
			return {
				...initialState,
				office: state.office,
				initialOffice: state.initialOffice,
				initialEmployee: state.initialEmployee,
			};
		case RESET_ON_MEETING_ROOMS_TAB_CHANGE: {
			return {
				...state,
				firstDay: moment().startOf('day'),
				lastDay: moment().startOf('day'),
				timelineDate: moment().startOf('day'),
				isWorkingHours: true,
				isAllDayChecked: false,
				weeksCount: 0,
				selectedItem: null,
				selectedTime: null,
			};
		}

		case SET_HIGHLIGHTED_DAYS:
			return {
				...state,
				highlightedDays: payload as unknown as HighlightedDays,
			};
		case SET_WORKPLACES:
			return {
				...state,
				workplaces: payload as Workplace[],
			};
		case SET_ERROR:
			return {
				...state,
				error: payload as string | null,
			};

		case INIT:
			return {
				...state,
				selectedItem: null,
				employee: state.initialEmployee,
			};
		case SET_WEEKS_COUNT:
			return { ...state, weeksCount: payload as number };
		case SET_HAS_WORKPLACE_COLLISION:
			return {
				...state,
				hasWorkplaceCollision: payload as boolean | undefined,
			};
		default:
			return state;
	}
};

function BookingProvider({
	children,
	initialBookingContextState,
}: BookingProviderProps) {
	const [
		{
			meetingRoomCollisions,
			timelineDate,
			isAllDayChecked,
			isWorkingHours,
			selectedTime,
			selectedItem,
			selectedSpaceId,
			firstDay,
			lastDay,
			employee,
			office,
			initialOffice,
			highlightedDays,
			workplaces,
			initialEmployee,
			error,
			hasWorkplaceCollision,
			weeksCount,
		},
		dispatch,
	] = useReducer(reducer, initialBookingContextState);

	const callback: any = useCallback(
		(type: string) => (payload: Payload) => dispatch({ type, payload }),
		[]
	);

	const selectedWorkplace = useMemo(
		() =>
			(workplaces as Array<Workplace>).find(
				(place) => place.workplaceId === selectedItem?.id
			),
		[workplaces, selectedItem]
	);

	useEffect(() => {
		callback(RESET_ON_OFFICE_CHANGE)(null);
		const hasSpaces = office.workspaces.length > 0;
		callback(SET_SELECTED_SPACE_ID)(hasSpaces ? office.workspaces[0].id : null);
	}, [office]);

	const isTimelineInRange = useMemo(
		() => timelineDate?.isBetween(firstDay, lastDay, 'day', '[]'),
		[timelineDate, firstDay, lastDay]
	);

	const areMeetingRoomsSelected = useMemo(
		() => areMeetingRoomsOpen(selectedSpaceId),
		[selectedSpaceId]
	);

	const areMeetingRoomDisabled = useMemo(() => {
		if (areMeetingRoomsSelected) {
			return (
				!selectedTime || meetingRoomCollisions.length > 0 || !isTimelineInRange
			);
		}

		return false;
	}, [
		areMeetingRoomsSelected,
		selectedTime,
		meetingRoomCollisions,
		isTimelineInRange,
	]);

	const isBlockedDesk = useMemo(() => {
		if (!selectedItem) {
			return false;
		}

		return workplaces.some(
			(workplace) =>
				workplace.workplaceId === selectedItem.id && workplace.isBlocked
		);
	}, [selectedItem]);

	const value: BookingContextValues = useMemo(
		() => ({
			meetingRoomCollisions,
			isAllDayChecked,
			isWorkingHours,
			selectedTime,
			selectedSpaceId,
			selectedItem,
			firstDay,
			lastDay,
			employee,
			office,
			initialOffice,
			initialEmployee,
			highlightedDays,
			workplaces,
			error,
			weeksCount,
			selectedWorkplace,
			timelineDate,
			hasWorkplaceCollision,
			setIsAllDayChecked: callback(SET_ALL_DAY_CHECKED),
			setIsWorkingHours: callback(SET_IS_WORKING_HOURS),
			setSelectedItem: callback(SET_SELECTED_ITEM),
			setTimelineDate: callback(SET_TIMELINE_DATE),
			setSelectedSpaceId: callback(SET_SELECTED_SPACE_ID),
			setFirstDay: callback(SET_FIRST_DAY),
			setLastDay: callback(SET_LAST_DAY),
			setSelectedTime: callback(SET_SELECTED_TIME),
			setEmployee: callback(SET_EMPLOYEE),
			setOffice: callback(SET_OFFICE),
			setHighlightedDays: callback(SET_HIGHLIGHTED_DAYS),
			setWorkplaces: callback(SET_WORKPLACES),
			setWeeksCount: callback(SET_WEEKS_COUNT),
			setHasWorkplaceCollision: callback(SET_HAS_WORKPLACE_COLLISION),
			setError: callback(SET_ERROR),
			init: callback(INIT),
			resetOnMeetingTabChange: callback(RESET_ON_MEETING_ROOMS_TAB_CHANGE),
			setMeetingRoomCollisions: callback(SET_MEETING_ROOM_COLLISIONS),
			setInitialTimelineDate: callback(SET_INITIAL_TIMELINE_DATE),
			areMeetingRoomDisabled,
			areMeetingRoomsSelected,
			isTimelineInRange,
			isBlockedDesk,
		}),
		[
			meetingRoomCollisions,
			isAllDayChecked,
			isWorkingHours,
			selectedTime,
			selectedSpaceId,
			selectedItem,
			firstDay,
			lastDay,
			employee,
			office,
			initialOffice,
			initialEmployee,
			highlightedDays,
			workplaces,
			error,
			weeksCount,
			selectedWorkplace,
			timelineDate,
			areMeetingRoomDisabled,
			areMeetingRoomsSelected,
			isTimelineInRange,
			hasWorkplaceCollision,
			isBlockedDesk,
		]
	);

	return (
		<BookingContext.Provider value={value as unknown as BookingContextValues}>
			{children}
		</BookingContext.Provider>
	);
}

export const useBookingContext = () => useContext(BookingContext);

export const withBookingProvider = (Component: ComponentType<any>) =>
	function WithBookingProvider() {
		const { id, firstName, lastName, location, rightPosition } = useAuth();

		const {
			configurableValues: { offices },
		} = useConfiguration();

		const initialOfficeValue: OfficeType | null = useMemo(
			() => getInitialBookingOfficeByUserLocation(offices, location),
			[offices, location]
		);

		const initialSpaceId: number | null = useMemo(() => {
			const hasSpaces = initialOfficeValue
				? initialOfficeValue.workspaces.length > 0
				: false;

			return hasSpaces && initialOfficeValue
				? initialOfficeValue.workspaces[0].id
				: null;
		}, [initialOfficeValue]);

		const initialEmployeeValue: Employee | null = useMemo(() => {
			if (!id || !firstName || !lastName) {
				return null;
			}

			return {
				id,
				name: getFullName(firstName, lastName),
				positionDescription: rightPosition,
			};
		}, [id, firstName, lastName]);

		if (!initialOfficeValue || !initialEmployeeValue) {
			return null;
		}

		const initialBookingContextState: BookingContextInitialValues = {
			...initialState,
			office: initialOfficeValue,
			selectedSpaceId: initialSpaceId,
			initialOffice: initialOfficeValue,
			initialEmployee: initialEmployeeValue,
			employee: initialEmployeeValue,
		};

		return (
			<BookingProvider initialBookingContextState={initialBookingContextState}>
				<Component />
			</BookingProvider>
		);
	};
