import { useTheme } from '@emotion/react';
import { Autoselect } from '@mms/mms-ui-library';
import { isEqual } from 'lodash';
import React, { useMemo } from 'react';

import { Roles } from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { useBookingContext } from '@/context/Booking';
import { getEmployeeOptions } from '@/helpers/getEmployeeOptions';
import { useUsersLookup } from '@/queries/useUsersLookup';

const getKey = (item: { id: number }) => item.id;

const areOptionsEqual = (
	option: { id: number },
	autoselectValue: { id: number }
) => (option as { id: number }).id === (autoselectValue as { id: number }).id;

const AutoselectMemoized = React.memo(Autoselect, isEqual) as typeof Autoselect;
const BOOKING_USERS_QUERY_PARAMS = {
	search: '',
	isActive: true,
};

export function BookingUsersAutoselect() {
	const { employee, initialEmployee, setEmployee } = useBookingContext();
	const { role } = useAuth();

	const theme = useTheme();

	const { data, isFetching } = useUsersLookup({
		...BOOKING_USERS_QUERY_PARAMS,
		queryOptions: { enabled: role !== Roles.SE },
	});
	const employeeOptions = useMemo(
		() => getEmployeeOptions(data, isFetching),
		[data, isFetching]
	);

	const label = employee
		? employee.positionDescription
		: initialEmployee.positionDescription;

	const value = employee && employee.name ? employee : initialEmployee;

	return (
		<AutoselectMemoized
			label={label}
			value={value}
			valueField="name"
			options={employeeOptions}
			onChange={setEmployee}
			valueToClean={initialEmployee}
			labelAndErrorBgColor={theme.palette['primary-1']}
			getKey={getKey}
			checkIfOptionEqualToValue={areOptionsEqual}
		/>
	);
}
