import styled from '@emotion/styled';

import { BOOKING_BLOCK_HEADER_HEIGHT } from '../../constants';

const LOADER_SIZE = 40;

export const StyledList = styled('div')<{ isEmploye?: boolean }>(
	({ theme }) => ({
		width: '100%',
		boxSizing: 'border-box',
		borderLeft: `1px solid ${theme.palette['primary-4']}`,
		height: `calc(100% - ${BOOKING_BLOCK_HEADER_HEIGHT}px)`,
		overflow: 'hidden',
	})
);

export const LoaderWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: theme.spaces.m,

	'& > svg': {
		width: LOADER_SIZE,
		height: LOADER_SIZE,
	},
}));
