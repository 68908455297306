import { CELL_WIDTH, BOOKED_LABEL_MARGIN } from '../constants';

import { getFormattedDateTimePeriodWithUTC } from '@/helpers/bookingFormatters';

export const getXPos = (cellIndex: number) => cellIndex * CELL_WIDTH;

export const getWidth = (duration: number) =>
	duration * CELL_WIDTH - BOOKED_LABEL_MARGIN * 2;

export const formatTimeInterval = (start: string, end: string): string => {
	const formatedTime = getFormattedDateTimePeriodWithUTC(start, end);

	return formatedTime;
};
