import { useTheme } from '@emotion/react';
import {
	BookingCalendarIcon,
	DatePicker,
	Typography,
} from '@mms/mms-ui-library';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { currentDate, DAY_AFTER_THREE_MONTHS } from '@/constants/date';
import { useBookingContext } from '@/context/index';

import {
	DatePickersContentWrapper,
	DatePickersHeader,
	DatePickersWrapper,
} from './styled';
import { getDatePickerErrorMessage } from './utils';

export function DatePickers() {
	const {
		firstDay,
		setFirstDay,
		lastDay,
		setLastDay,
		setError,
		error,
		timelineDate,
		areMeetingRoomsSelected,
		setTimelineDate,
	} = useBookingContext();
	const [disabled, setDisabled] = useState(false);
	const theme = useTheme();

	const handleChangeFirstDay = useCallback(
		(newValue: Date | null) => {
			if (!newValue) {
				setFirstDay(null);
				setLastDay(null);
				setDisabled(true);
				setError('invalidDate');

				return;
			}

			const momentValue = moment(newValue).startOf('day');

			if (momentValue?.isBefore(currentDate.clone(), 'day')) {
				setFirstDay(momentValue);

				if (
					areMeetingRoomsSelected &&
					!momentValue.isSame(timelineDate, 'day')
				) {
					setTimelineDate(momentValue);
				}
				setError('minDate');

				return;
			}

			if (momentValue?.isAfter(DAY_AFTER_THREE_MONTHS, 'day')) {
				setFirstDay(momentValue);

				if (
					areMeetingRoomsSelected &&
					!momentValue.isSame(timelineDate, 'day')
				) {
					setTimelineDate(momentValue);
				}
				setError('maxDate');

				return;
			}

			if (!momentValue?.isValid()) {
				setLastDay(momentValue);
				setError('pastDate');
				setDisabled(true);

				return;
			}

			if (momentValue.isBefore(moment(), 'day')) {
				setLastDay(momentValue);
				setError('pastDate');

				return;
			}

			setDisabled(false);
			setError(null);
			setFirstDay(momentValue);

			if (areMeetingRoomsSelected && !momentValue.isSame(timelineDate, 'day')) {
				setTimelineDate(momentValue);
			}

			if (!momentValue.isSame(firstDay)) {
				setLastDay(momentValue);
			}
		},
		[disabled, firstDay, timelineDate, areMeetingRoomsSelected]
	);

	const handleChangeLastDay = useCallback(
		(newValue: Date | null) => {
			if (!newValue) {
				setLastDay(null);
				setError('invalidDate');

				return;
			}
			const momentValue = moment(newValue);

			if (momentValue?.isBefore(firstDay, 'day')) {
				setLastDay(momentValue);
				setError('minDate');

				return;
			}

			if (momentValue?.isAfter(DAY_AFTER_THREE_MONTHS, 'day')) {
				setLastDay(momentValue);
				setError('maxDate');

				return;
			}

			if (momentValue?.isBefore(moment(), 'day')) {
				setLastDay(momentValue);
				setError('pastDate');

				return;
			}

			if (firstDay?.isBefore(moment(), 'day')) {
				setLastDay(momentValue);
				setError('pastDate');

				return;
			}
			setError(null);
			setLastDay(momentValue);
		},
		[firstDay, lastDay, setError, setLastDay]
	);

	useEffect(() => {
		const startOfCurrentDate = currentDate.startOf('day');

		const isDateValid =
			lastDay?.isSameOrAfter(firstDay) &&
			firstDay?.isSameOrAfter(startOfCurrentDate);

		if (isDateValid) {
			setError(null);
			setDisabled(false);
		}
	}, [firstDay, lastDay]);

	const datePickerStylesConfig = useMemo(
		() => ({
			label: {
				background: theme.palette['primary-1'],
			},
			errorText: {
				wrapper: {
					background: theme.palette['primary-1'],
				},
			},
		}),
		[theme]
	);

	const errorMessage = useMemo(
		() => getDatePickerErrorMessage(error),

		[error]
	);

	return (
		<DatePickersWrapper>
			<DatePickersHeader>
				<BookingCalendarIcon />
				<Typography variant="s-600">Date</Typography>
			</DatePickersHeader>
			<DatePickersContentWrapper>
				<DatePicker
					innerLabel="From"
					value={firstDay?.toDate() || null}
					onChange={handleChangeFirstDay}
					errorText={errorMessage}
					minDate={currentDate.toDate()}
					maxDate={DAY_AFTER_THREE_MONTHS.toDate()}
					calendarPosition="bottom-start"
					stylesConfig={datePickerStylesConfig}
				/>
				<DatePicker
					innerLabel="To"
					value={lastDay?.toDate() || null}
					disabled={disabled}
					onChange={handleChangeLastDay}
					calendarPosition="bottom-start"
					minDate={firstDay ? firstDay.toDate() : undefined}
					maxDate={DAY_AFTER_THREE_MONTHS.toDate()}
					errorText={errorMessage}
					stylesConfig={datePickerStylesConfig}
				/>
			</DatePickersContentWrapper>
		</DatePickersWrapper>
	);
}
