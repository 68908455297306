// TODO: Delete or move to UI-kit when Minsk office booking becomes available
export function WarningIcon() {
	return (
		<svg
			width="46"
			height="46"
			viewBox="0 0 46 46"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_25309_103680)">
				<path
					d="M45.2667 35.5213L27.6843 5.06775C26.7067 3.37438 24.9555 2.36328 23 2.36328C21.0445 2.36328 19.2933 3.37438 18.3156 5.06775L0.733326 35.5212C-0.244442 37.2148 -0.244442 39.2368 0.733326 40.9302C1.71109 42.6237 3.46214 43.6347 5.41768 43.6347H40.5823C42.5377 43.6347 44.2889 42.6236 45.2667 40.9303C46.2445 39.2368 46.2445 37.2148 45.2667 35.5213ZM42.9328 39.5829C42.4421 40.4326 41.5635 40.9399 40.5823 40.9399H5.41768C4.43641 40.9399 3.55774 40.4326 3.06719 39.5829C2.57665 38.7331 2.57665 37.7186 3.06719 36.8689L20.6497 6.41532C21.1402 5.56558 22.0189 5.05832 23.0001 5.05832C23.9812 5.05832 24.8599 5.56558 25.3505 6.41532L42.9328 36.8689C43.4233 37.7186 43.4233 38.7331 42.9328 39.5829Z"
					fill="#F17900"
				/>
				<path
					d="M21.6524 15.8125H24.3473V29.2874H21.6524V15.8125ZM22.9999 31.9826C22.0092 31.9826 21.2032 32.7886 21.2032 33.7793C21.2032 34.77 22.0092 35.576 22.9999 35.576C23.9906 35.576 24.7966 34.77 24.7966 33.7793C24.7966 32.7886 23.9906 31.9826 22.9999 31.9826Z"
					fill="#F17900"
				/>
			</g>
			<defs>
				<clipPath id="clip0_25309_103680">
					<rect width="46" height="46" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
