import { OfficeSelectorOption } from '@/pages/Booking/types';
import { LocationTypeId } from '@/types/Configuration';

export const BookPlaceType = {
	Booked: 'booked',
	Selected: 'selected',
	Blocked: 'blocked',
	YourBlocked: 'yourBlocked',
};

export const BookType = {
	Single: 'single',
	Repetitive: 'repetitive',
	Multiple: 'multiple',
};

export const bookingTitleAbriviations: Record<string, string> = {
	[LocationTypeId.Warsaw]: 'W',
	[LocationTypeId.Minsk]: 'M',
};

export const bookingOfficeNames: Record<string, string> = {
	[LocationTypeId.Warsaw]: 'Office W',
	[LocationTypeId.Minsk]: 'Office M',
};
export const officeSelectorOptions: Array<OfficeSelectorOption> = [
	{
		name: bookingOfficeNames[LocationTypeId.Warsaw],
		officeLocation: LocationTypeId.Warsaw,
	},
	{
		name: bookingOfficeNames[LocationTypeId.Minsk],
		officeLocation: LocationTypeId.Minsk,
	},
];

export const REPEAT_WEEKS_MIN_VALUE = 1;
export const REPEAT_WEEKS_MAX_VALUE = 4;

export enum RequestTypes {
	CreateSelf = 'CreateSelf',
	CreateBlock = 'CreateBlock',
	CreateForUser = 'CreateForUser',
}

export const BLOCK_DIALOG_HEADER = 'DESK BLOCKING';
export const UNBLOCK_DIALOG_HEADER = 'DESK UNBLOCKING';
export const CANCEL_DIALOG_HEADER = 'BOOKING CANCELATION';

export const COLORS_ENUM = {
	Available: 'available',
	Blocked: 'blocked',
	Booked: 'booked',
	Selected: 'selected',
};
export const COLORS = Object.values(COLORS_ENUM);

export enum MinskSpaceAbriviations {
	A = 'A',
	B = 'B',
	C = 'C',
	D = 'D',
}

export const DEFAULT_MINSK_SPACE_ABRIVIATION = MinskSpaceAbriviations.A;

export const MINSK_SPACE_ABRIVIATIONS: { [key: number]: string } = {
	1: MinskSpaceAbriviations.A,
	2: MinskSpaceAbriviations.B,
	3: MinskSpaceAbriviations.C,
	4: MinskSpaceAbriviations.D,
};

export const WARSAW_SPACE_ABRIVIATION = 'W';

export const ALL_DAY_TIME = {
	from: '00:00:01.000',
	to: '23:59:59.000',
};

const HH_MM_LENGTH_WITH_SEPARATOR = 5;

export const ALL_DAY_TIME_HH_MM = {
	from: ALL_DAY_TIME.from.slice(0, HH_MM_LENGTH_WITH_SEPARATOR),
	to: ALL_DAY_TIME.to.slice(0, HH_MM_LENGTH_WITH_SEPARATOR),
};

export const TIME_SELECTOR_STEP_IN_MINUTES = 15;
export const WORKING_DAY_START_HOUR = 8;
export const WORKING_DAY_END_HOUR = 19;
export const NOT_WORKING_DAY_START_HOUR = 0;
export const NOT_WORKING_DAY_END_HOUR = 23;

export const VIEW_TIME_FORMAT = 'HH:mm';
export const RESPONSE_TIME_FORMAT = 'HH:mm:sss';
export const UTC_RESPONSE_TIME_FORMAT = 'HH:mm:ss';
export const RESPONSE_ALL_DAY_START_TIME_FORMAT = '00:00:01';
export const RESPONSE_ALL_DAY_END_TIME_FORMAT = '23:59:59';
export const VIEW_DAY_END_TIME = '00:00:00';
