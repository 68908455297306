import {
	CrossCircle,
	Loader,
	PopUp,
	Scrollbar,
	Unblock,
} from '@mms/mms-ui-library';
import React, { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { getBookingDeletingToastMessage } from '../../utils';
import { DeleteDeskBookingPopupBody } from '../PopUps/DeleteDeskBookingPopupBody';
import { DeleteMeetingRoomBookingPopupBody } from '../PopUps/DeleteMeetingRoomBookingPopupBody';

import {
	BookingQueriesKeys,
	isEmployee,
	NotificationType,
	Roles,
} from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { useBookingContext } from '@/context/Booking';
import { useToast } from '@/context/Toast';
import { SelectedBookingItemType } from '@/context/types';
import { createToast } from '@/helpers/createToast';
import { getDeleteDialogHeader } from '@/helpers/index';
import { isMeetingRoom } from '@/helpers/isMeetingRoom';
import { useDeleteBooking } from '@/queries/booking/index';
import { useDeleteMeetingRoomBooking } from '@/queries/booking/useDeleteMeetingRoomBooking';
import { DeskBooking, MeetingRoomBooking } from '@/types/Booking';

import { DeskBookingListItem } from './DeskBookingListItem';
import { RoomBookingListItem } from './RoomBookingListItem';
import { LoaderWrapper, StyledList } from './styles';
import { BookingListProps } from './types';
import { convertBookingItemToPopupData, getOrderedBookings } from './utils';

export function BookingList({ data, showLoader }: BookingListProps) {
	const { id, role } = useAuth();

	const toast = useToast();
	const queryClient = useQueryClient();
	const { office, areMeetingRoomsSelected } = useBookingContext();
	const [itemToDelete, setItemToDelete] = useState<
		DeskBooking | MeetingRoomBooking | null
	>(null);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const { mutate: deleteDeskBooking } = useDeleteBooking(
		{
			onSuccess: () => {
				queryClient.invalidateQueries(BookingQueriesKeys.bookings);
				queryClient.refetchQueries(BookingQueriesKeys.reservations);

				if (itemToDelete && !isMeetingRoom(itemToDelete)) {
					const { isBlock, userId } = itemToDelete;

					const isOwnBooking = userId === id;

					toast.open(
						createToast(
							NotificationType.SUCCESS,
							null,
							getBookingDeletingToastMessage(isBlock, isOwnBooking)
						)
					);
				}
			},
		},
		toast
	);

	const { mutate: deleteMeetingRoomBooking } = useDeleteMeetingRoomBooking(
		{
			onSuccess: () => {
				queryClient.invalidateQueries(BookingQueriesKeys.bookings);
				queryClient.invalidateQueries(BookingQueriesKeys.meetingRoomCollisions);
				queryClient.refetchQueries(BookingQueriesKeys.meetingRoomReservations);

				const bookingEntryUserId = itemToDelete?.userId;
				const isOwnBooking = bookingEntryUserId === id;

				toast.open(
					createToast(
						NotificationType.SUCCESS,
						null,
						getBookingDeletingToastMessage(false, isOwnBooking)
					)
				);
			},
		},
		toast
	);

	const sortedData = useMemo(
		() => (data ? getOrderedBookings(data, areMeetingRoomsSelected) : []),
		[data, areMeetingRoomsSelected]
	);

	const handleCloseDialog = useCallback(() => {
		setIsDialogOpen(false);
		setItemToDelete(null);
	}, []);

	const initiateBookDelete = useCallback(
		(item: DeskBooking | MeetingRoomBooking) => () => {
			setItemToDelete(item);
			setIsDialogOpen(true);
		},
		[]
	);

	const handleDelete = useCallback(() => {
		if (!itemToDelete) {
			return;
		}

		if (isMeetingRoom(itemToDelete)) {
			deleteMeetingRoomBooking({ entryId: itemToDelete.meetingRoomEntryId });
			setIsDialogOpen(false);

			return;
		}

		const { bookingId } = itemToDelete;

		deleteDeskBooking({ id: bookingId });
		setIsDialogOpen(false);
	}, [itemToDelete]);

	const convertedItemToDelete = convertBookingItemToPopupData(itemToDelete);

	return (
		<>
			<StyledList isEmploye={isEmployee(role)}>
				{showLoader ? (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				) : (
					<Scrollbar
						customScrollbarWidth={3}
						scroll="vertical"
						maxHeight="100%"
						maxWidth="100%"
					>
						{sortedData.map((bookings) =>
							bookings.type === SelectedBookingItemType.Room ? (
								<React.Fragment key={bookings.type}>
									{bookings.items.map((props) => (
										<RoomBookingListItem
											key={props.meetingRoomEntryId}
											handleDelete={initiateBookDelete(props)}
											{...props}
										/>
									))}
								</React.Fragment>
							) : (
								<React.Fragment key={bookings.type}>
									{bookings.items.map((props) => (
										<DeskBookingListItem
											office={office}
											key={props.bookingId}
											handleDelete={initiateBookDelete(props)}
											currentUserId={id}
											userRole={role as Roles}
											{...props}
										/>
									))}
								</React.Fragment>
							)
						)}
					</Scrollbar>
				)}
			</StyledList>
			{isDialogOpen && convertedItemToDelete && (
				<PopUp
					title={getDeleteDialogHeader(convertedItemToDelete.isBlock)}
					onClose={handleCloseDialog}
					type="approve"
					showCloseButton={false}
					headerIcon={
						convertedItemToDelete.isBlock ? <Unblock /> : <CrossCircle />
					}
					controls={{
						negativeControl: { onClick: handleCloseDialog },
						positiveControl: { onClick: handleDelete },
					}}
				>
					{convertedItemToDelete.type === SelectedBookingItemType.Room ? (
						<DeleteMeetingRoomBookingPopupBody
							bookingItem={convertedItemToDelete}
						/>
					) : (
						<DeleteDeskBookingPopupBody bookingItem={convertedItemToDelete} />
					)}
				</PopUp>
			)}
		</>
	);
}
