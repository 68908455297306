import styled from '@emotion/styled';
import { Typography } from '@mms/mms-ui-library';
import { alpha } from '@mui/material';

import { DEFAULT_ICON_SIZE } from '@/constants/commonStyles';
import { COLOR_MAP } from '@/pages/Booking/constants';

import { StyledListItemProps } from './types';

const BOOKING_LIST_ITEM_BEFORE_WIDTH = 6;
const HOVER_ALPHA_VALUE = 0.08;
const CLOSE_ICON_MAX_WIDTH = 56;
const DIVIDER_HEIGHT = 1;
const LIST_ITEM_WITH_ICON_HEIGHT = 21;

export const BookingListItem = styled('div')<StyledListItemProps>(
	({ theme, type }) => ({
		position: 'relative',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'space-between',
		padding: `${theme.spaces.s}px`,
		paddingLeft: `${theme.spaces.m}px`,
		transition: 'all 0.2s ease-in-out',
		'&::before': {
			content: '" "',
			position: 'absolute',
			height: '100%',
			top: 0,
			left: 0,
			backgroundColor:
				theme.palette[
					COLOR_MAP[
						type as keyof typeof COLOR_MAP
					] as keyof typeof theme.palette
				],
			width: BOOKING_LIST_ITEM_BEFORE_WIDTH,
		},
		'&:hover': {
			backgroundColor: alpha(
				theme.palette.timeSheets.mainHover,
				HOVER_ALPHA_VALUE
			),
		},
	})
);

export const ListItemContent = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spaces.xs,
}));

export const BookingListTypography = styled(Typography)({
	display: 'inlineBlock',
	overflow: 'hidden',
	textWrap: 'wrap',
	overflowWrap: 'anywhere',
	whiteSpace: 'pre-wrap',
});

export const IconWrapper = styled('div')(({ theme }) => ({
	color: theme.palette['accent-6'],
}));

export const IconButton = styled(IconWrapper)(() => ({
	cursor: 'pointer',
	width: DEFAULT_ICON_SIZE,
	height: DEFAULT_ICON_SIZE,
}));

export const IconCloseWrapper = styled('div')(() => ({
	display: 'flex',
	maxWidth: CLOSE_ICON_MAX_WIDTH,
	justifyContent: 'center',
	alignItems: 'flex-start',
}));

export const Divider = styled('div')(({ theme }) => ({
	width: '100%',
	height: DIVIDER_HEIGHT,
	background: theme.palette['primary-4'],

	'&:last-child': {
		display: 'none',
	},
}));

export const ItemRowWithIcon = styled('div')(({ theme }) => ({
	display: 'flex',
	gap: theme.spaces.xs,
	height: LIST_ITEM_WITH_ICON_HEIGHT,
}));
