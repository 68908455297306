export const getFullName = (firstName: string, lastName: string) =>
	`${lastName} ${firstName}`;

export const formatFullName = (lastName: string, firstName: string) =>
	getFullName(firstName || '', lastName || '-');

export const splitFullName = (fullName: string) => {
	const fullNameArray = fullName.split(' ');

	return {
		firstName: fullNameArray[0] || '',
		lastName: fullNameArray[1] || '',
	};
};
