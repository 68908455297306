import { OfficeSelector } from '../OfficeSelector';

import {
	BookingsHeaderRightMenuWrapper,
	BookingsHeaderWrapper,
	BookingsTitle,
} from './styled';

export function BookingBlockHeader() {
	return (
		<BookingsHeaderWrapper>
			<BookingsTitle variant="small">Bookings</BookingsTitle>
			<BookingsHeaderRightMenuWrapper>
				<OfficeSelector />
			</BookingsHeaderRightMenuWrapper>
		</BookingsHeaderWrapper>
	);
}
