import { Switch, Typography } from '@mms/mms-ui-library';
import AccordionDetails from '@mui/material/AccordionDetails';
import { memo } from 'react';

import { RepeatWeeks } from '@/pages/Booking/components';

import {
	StyledAccordionSummary,
	StyledAccordion,
	RepetativeSwitchContainer,
} from './styled';
import type { RepetitiveInnerProps } from './types';

const REPETATIVE_SWITCH_NAME = 'repeat-switch';

function RepetitiveInner({
	isChecked,
	isControlDisabled,
	weeksCount,
	handleWeeksChange,
	handleChange,
}: RepetitiveInnerProps) {
	return (
		<>
			<RepetativeSwitchContainer htmlFor={REPETATIVE_SWITCH_NAME}>
				<Switch
					checked={isChecked && !isControlDisabled}
					onChange={handleChange}
					name={REPETATIVE_SWITCH_NAME}
					id={REPETATIVE_SWITCH_NAME}
					disabled={isControlDisabled}
				/>
				<Typography variant="s-400">Repeat booking</Typography>
			</RepetativeSwitchContainer>

			<StyledAccordion
				expanded={isChecked && !isControlDisabled}
				disableGutters
				elevation={0}
			>
				<StyledAccordionSummary />
				<AccordionDetails>
					<RepeatWeeks value={weeksCount} onChange={handleWeeksChange} />
				</AccordionDetails>
			</StyledAccordion>
		</>
	);
}

export const Repetitive = memo(RepetitiveInner);
