import { Button, ChevronLeft, Switch, Typography } from '@mms/mms-ui-library';
import moment from 'moment';

import { MobileSwitchWrapper, Row } from '../styles';

import { useIsMobileView } from './hooks/useIsMobileView';
import {
	ChevronWrapper,
	MobileTimeTypography,
	TimelineControlsWrapper,
} from './styles';
import { TimelineControlsProps } from './types';

const TODAY_BUTTON_MIN_WIDTH = 102;
const CONTROLS_BREAKPOINT = 472;
const SWITCH_LABEL_TEXT = 'Working hours';
const TODAY_BUTTON_TEXT = 'Today';

export function TimelineControls({
	isWorkingHoursOnly,
	onWorkingHoursChange,
	selectedDate,
	onGoBackwardDate,
	onGoForwardDate,
	onGoTodayDate,
}: TimelineControlsProps) {
	const formattedTime = selectedDate.toLocaleDateString('en-GB', {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	});

	const todayStartOfDay = moment().startOf('day');
	const selectedDateStartOfDay = moment(selectedDate).startOf('day');

	const isTodayButtonDisabled = todayStartOfDay.isSame(selectedDateStartOfDay);

	const { isMobile, containerRef } = useIsMobileView(CONTROLS_BREAKPOINT);

	if (isMobile) {
		const showTodayButton = !isTodayButtonDisabled;

		return (
			<TimelineControlsWrapper isMobileView ref={containerRef}>
				<MobileSwitchWrapper>
					<Switch
						checked={isWorkingHoursOnly}
						onChange={onWorkingHoursChange}
					/>
					<Typography variant="s-400">{SWITCH_LABEL_TEXT}</Typography>
				</MobileSwitchWrapper>
				<ChevronWrapper isBackward onClick={onGoBackwardDate}>
					<ChevronLeft />
				</ChevronWrapper>
				<MobileTimeTypography variant="s-400">
					{formattedTime}
				</MobileTimeTypography>
				{showTodayButton && (
					<Button
						style={{ minWidth: TODAY_BUTTON_MIN_WIDTH }}
						size="small"
						color="base"
						fullWidth={false}
						onClick={onGoTodayDate}
						variant="thin"
					>
						{TODAY_BUTTON_TEXT}
					</Button>
				)}
				<ChevronWrapper onClick={onGoForwardDate}>
					<ChevronLeft />
				</ChevronWrapper>
			</TimelineControlsWrapper>
		);
	}

	return (
		<TimelineControlsWrapper ref={containerRef}>
			<Row spacing="s">
				<Switch checked={isWorkingHoursOnly} onChange={onWorkingHoursChange} />
				<Typography variant="s-400">{SWITCH_LABEL_TEXT}</Typography>
			</Row>
			<Button
				style={{ minWidth: TODAY_BUTTON_MIN_WIDTH }}
				disabled={isTodayButtonDisabled}
				size="small"
				color="base"
				fullWidth={false}
				onClick={onGoTodayDate}
				variant="thin"
			>
				{TODAY_BUTTON_TEXT}
			</Button>
			<Row spacing="l">
				<ChevronWrapper isBackward onClick={onGoBackwardDate}>
					<ChevronLeft />
				</ChevronWrapper>
				<ChevronWrapper onClick={onGoForwardDate}>
					<ChevronLeft />
				</ChevronWrapper>
			</Row>
			<Typography variant="s-400">{formattedTime}</Typography>
		</TimelineControlsWrapper>
	);
}
