import { createContext, useMemo, useContext, PropsWithChildren } from 'react';

import { defaultConfiguration } from '@/constants/defaultValues';
import { useGetConfiguration } from '@/queries/useGetConfiguration';

import { useAuth } from './AuthContext';
import type { ConfigurationContextValues } from './types';

const ConfigurationContext = createContext({} as ConfigurationContextValues);

export function ConfigurationProvider({ children }: PropsWithChildren) {
	const { token } = useAuth();

	const { data, isFetching, dataUpdatedAt } = useGetConfiguration({
		enabled: Boolean(token),
		refetchOnWindowFocus: false,
	});

	const contextValue: ConfigurationContextValues = useMemo(
		() => ({
			...(data || defaultConfiguration),
			isLoading: isFetching,
		}),
		[dataUpdatedAt, isFetching]
	);

	return (
		<ConfigurationContext.Provider value={contextValue}>
			{children}
		</ConfigurationContext.Provider>
	);
}

export const useConfiguration = () => useContext(ConfigurationContext);
