import { Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

import { BOOKING_TABS_CHANGE_SCREEN_WIDTH } from '../../constants';

import { bookingGridAreas } from './constants';

const { titleArea } = bookingGridAreas;

export const BookingWorkplaceWrapper = styled('div')(({ theme }) => ({
	height: '100%',
	minHeight: '100%',
	width: '100%',
	maxWidth: '100%',
	minWidth: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spaces.l,

	[theme.breakpoints.down(BOOKING_TABS_CHANGE_SCREEN_WIDTH)]: {
		rowGap: theme.spaces.l,
		justifySelf: 'center',
	},
}));

export const TitleWrapper = styled('div')(() => ({
	gridArea: titleArea,
}));

export const OfficeWarningContainer = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	height: '100%',
	alignItems: 'center',
}));

export const OfficeWarningMessageContainer = styled(OfficeWarningContainer)(
	({ theme }) => ({
		gap: theme.spaces.m,
		flexDirection: 'column',
	})
);

export const OfficeWarningTypograpphy = styled(Typography)(() => ({
	whiteSpace: 'normal',
	textAlign: 'center',
}));
