import { Typography, WarningIcon } from '@mms/mms-ui-library';

import { REPORT_GENERATING_FAILED } from '@/constants/errors';

import { ReportGeneratingFailureNotificationBody } from './styles';

export function ReportGeneratingFailureNotification() {
	return (
		<ReportGeneratingFailureNotificationBody>
			<WarningIcon />
			<Typography variant="s-400">{REPORT_GENERATING_FAILED}</Typography>
		</ReportGeneratingFailureNotificationBody>
	);
}
