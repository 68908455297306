import { FromToProps } from '@/components/Grid';

type Comparator<ValueType> = (a: ValueType, b: ValueType) => boolean;

export const arePrimitiveValuesEqual: Comparator<
	number | string | boolean | null | undefined | symbol | bigint
> = (a, b) => a === b;

export const areArraysEqualByPrimitiveItems: Comparator<(number | string)[]> = (
	a,
	b
) => a?.length === b?.length && a?.every((item) => b.includes(item));

export const areFromToCompatibleObjectsEqual: Comparator<
	FromToProps<Date | string>
> = (a, b) => a?.from === b?.from && a?.to === b?.to;
