import styled from '@emotion/styled';
import { Typography } from '@mms/mms-ui-library';

import { Row } from '../styles';

import { DEFAULT_CONTROL_HEIGHT } from '@/constants/commonStyles';
import { IconSize } from '@/constants/iconSizes';
import { WithMobileView } from '@/types/Filter';

const BACKWARD_ROTATION = '0deg';
const FORWARD_ROTATION = '180deg';

const mobileStyles = {
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
} as const;

export const TimelineControlsWrapper = styled(Row)<WithMobileView>(
	({ theme, isMobileView }) => ({
		justifyContent: 'flex-start',
		gap: theme.spaces.l,

		...(isMobileView ? mobileStyles : {}),
	})
);

export const MobileTimeTypography = styled(Typography)(() => ({
	height: DEFAULT_CONTROL_HEIGHT,
	lineHeight: `${DEFAULT_CONTROL_HEIGHT}px`,
}));

export const ChevronWrapper = styled('div')<{ isBackward?: boolean }>(
	({ isBackward }) => ({
		width: IconSize.M,
		height: IconSize.M,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transform: isBackward
			? `rotate(${BACKWARD_ROTATION})`
			: `rotate(${FORWARD_ROTATION})`,
	})
);
