import { MINUTES_IN_HOUR } from '../constants';

import { parseUTC } from './parseUTC';

export const convertISOToTimelineHours = (
	isoDate: string,
	baseHour: number
) => {
	const date = new Date(parseUTC(isoDate));
	const hours = date.getHours();
	const minutes = date.getMinutes();

	return hours - baseHour + minutes / MINUTES_IN_HOUR;
};
