import { parseISOPeriod } from './parsePeriod';

export function calculateDuration(
	fromDateTime: string,
	toDateTime: string,
	baseHour: number
): number {
	const { to, from } = parseISOPeriod(
		{
			from: fromDateTime,
			to: toDateTime,
			isOverlaped: false,
		},
		baseHour
	);

	return to - from;
}
