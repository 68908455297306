import styled from '@emotion/styled';
import { Button } from '@mms/mms-ui-library';

import { BOOKING_RADIUS } from '@/constants/borderRadiuses';
import { DEFAULT_CONTROL_HEIGHT } from '@/constants/commonStyles';
import { BOOKING_MOBILE_SCREEN_WIDTH } from '@/pages/Booking/constants';

const MAP_BUTTON_WIDTH = 105;
const CONTROLS_HEIGHT = 36;

export const ControlsWrapper = styled('div')<{ hasLegend: boolean }>(
	({ hasLegend }) => ({
		display: 'flex',
		justifyContent: hasLegend ? 'space-between' : 'flex-end',
		width: '100%',
		alignItems: 'center',
		height: CONTROLS_HEIGHT,
		flexShrink: 0,
	})
);

export const LegendWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	gap: theme.spaces.m,

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		marginTop: 0,
	},
}));

export const ColorTooltipTextWrapper = styled('div')(
	() =>
		({
			'&:first-letter': {
				textTransform: 'uppercase',
			},
		} as const)
);

export const MapButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette['accent-2'],
	width: MAP_BUTTON_WIDTH,
	height: DEFAULT_CONTROL_HEIGHT,
	fontFamily: 'inherit',
	borderRadius: BOOKING_RADIUS,
	border: 'none',
	cursor: 'pointer',
	paddingLeft: theme.spaces.m,
	paddingRight: theme.spaces.s,

	'& > span': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));
