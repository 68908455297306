import { WorkSpaceConfiguration } from './types';
import {
	generateMinskSpaceA,
	generateMinskSpaceB,
	generateMinskSpaceC,
	generateMinskSpaceD,
	generateWarasawSpace,
} from './utils';

export const bookingGridAreas = {
	titleArea: 'title',
	legendArea: 'legend',
	workplaceArea: 'workplace',
};

export const WARSAW_SPACE: WorkSpaceConfiguration = generateWarasawSpace();

type LocationSpaces = {
	[key: number]: WorkSpaceConfiguration;
};

export const MINSK_SPACES: LocationSpaces = {
	1: generateMinskSpaceA(),
	2: generateMinskSpaceB(),
	3: generateMinskSpaceC(),
	4: generateMinskSpaceD(),
};

export const MEETING_ROOMS_TAB_ID = 42;
// TODO: Delete when Minsk office booking becomes available
export const OFFICE_CLOSED_MESSAGE = {
	firstSentence: 'The Minsk office is currently closed.',
	secondSentence: 'We’ll notify you in Teams when booking is available.',
};
