import { breakpoints, useHasScreenWidth } from '@mms/mms-ui-library';
import Backdrop from '@mui/material/Backdrop';
import { useCallback } from 'react';

import { DeskInfo, WorkSpaceConfiguration } from '../../types';

import { useBookingContext } from '@/context/Booking';
import { SelectedBookingItemType } from '@/context/types';
import { WORKPLACE_ELEMENT_ID } from '@/pages/Booking/constants';

import { Desk } from './components/Desk';
import { DeskSize } from './components/Desk/types';
import { SpaceLayoutWrapper, WorkplaceWrapper } from './styled';

interface WorkplaceSpaceRendererProps {
	config: WorkSpaceConfiguration;
	deskSize: DeskSize;
	spaceId: number | null;
}

export function WorkplaceSpaceRenderer(props: WorkplaceSpaceRendererProps) {
	const { config, deskSize, spaceId } = props;
	const { seats, spaceAbriviation, SpaceLayout } = config;

	const {
		selectedItem,
		setSelectedItem,
		workplaces,
		lastDay,
		error,
		setHasWorkplaceCollision,
	} = useBookingContext();

	const handleClick = useCallback(
		(deskInfo: DeskInfo | null) => {
			if (deskInfo) {
				setSelectedItem({
					...deskInfo,
					spaceId,
					abriviation: spaceAbriviation,
					type: SelectedBookingItemType.Desk,
				});
			} else {
				setSelectedItem(null);
				setHasWorkplaceCollision(undefined);
			}
		},
		[lastDay, spaceId, spaceAbriviation]
	);

	const isDesktop = useHasScreenWidth(breakpoints.tablet);

	return (
		<WorkplaceWrapper scroll="both" maxHeight="100%" maxWidth="100%">
			<SpaceLayoutWrapper>
				<SpaceLayout key={spaceAbriviation} id={WORKPLACE_ELEMENT_ID}>
					{seats.map((seat) => {
						const workplace = workplaces.find(
							({ workplaceId }) => workplaceId === seat.id
						);

						return (
							<Desk
								{...seat}
								{...workplace}
								size={deskSize}
								isMobileView={isDesktop}
								spaceTitle={spaceAbriviation}
								key={seat.id}
								onClick={handleClick}
								isSelected={seat.id === selectedItem?.id}
							/>
						);
					})}
					{error && <Backdrop sx={{ position: 'absolute' }} open invisible />}
				</SpaceLayout>
			</SpaceLayoutWrapper>
		</WorkplaceWrapper>
	);
}
