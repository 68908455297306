import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Scrollbar, ThemeMode } from '@mms/mms-ui-library';

import { closedDrawerWidth } from './config';
import {
	BURGER_ICON_HOVER_CIRCLE_SIZE,
	HEADER_HEIGHT,
	HOVER_CIRCLE_TRANSITION_DURATION_IN_MS,
} from './constants';
import { MainContentProps } from './types';

export const AppWrapper = styled('div')(({ theme }) => ({
	height: '100%',
	paddingLeft: closedDrawerWidth,
	paddingTop: HEADER_HEIGHT,
	backgroundColor:
		theme.mode === ThemeMode.DARK
			? theme.palette['primary-11']
			: theme.palette['primary-2'],
	[theme.breakpoints.down(theme.breakpoints.desktopXS)]: {
		paddingLeft: 0,
	},
}));

export const HeaderIconWrapper = styled('div')(
	({ theme }) => css`
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;

		::before {
			content: '';
			position: absolute;
			z-index: -1;
			width: 0;
			height: 0;
			border-radius: 50%;
			background: transparent;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: ${HOVER_CIRCLE_TRANSITION_DURATION_IN_MS}ms ease;
		}

		:hover::before {
			width: ${BURGER_ICON_HOVER_CIRCLE_SIZE}px;
			height: ${BURGER_ICON_HOVER_CIRCLE_SIZE}px;
			transition: ${HOVER_CIRCLE_TRANSITION_DURATION_IN_MS}ms ease;
			background: ${theme.mode === ThemeMode.LIGHT
				? theme.palette['primary-8']
				: theme.palette['accent-1']};
		}
	`
);

export const MainContent = styled('main')<MainContentProps>(
	({ theme, withPaddings, withAfter }) => ({
		padding: withPaddings
			? `${theme.spaces.m}px ${theme.spaces.l}px ${theme.spaces.l}px`
			: 0,
		position: 'relative',
		flexGrow: 1,
		height: '100%',
		[theme.breakpoints.down(theme.breakpoints.desktopXS)]: {
			padding: withPaddings ? theme.spaces.m : 0,
		},
		'&:after': withAfter
			? {
					content: '""',
					display: 'block',
					height: theme.spaces.l,
			  }
			: undefined,
	})
);

export const StyledScroll = styled(Scrollbar)({
	width: '100%',
	height: '100%',
	scrollMarginBottom: 16,
});

export const LoaderWrapper = styled('div')({
	position: 'absolute',
	width: '100%',
	top: 54,
	left: 0,
	zIndex: 1500,
});
