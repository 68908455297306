import { useMemo } from 'react';

import { useConfiguration } from '../context';

export const useFeatureFlag = (featureName: string) => {
	const { features } = useConfiguration();

	return useMemo(() => {
		const featureFlag = features.find(
			(feature) => feature.featureName === featureName
		);

		if (featureFlag) {
			return featureFlag.isEnabled;
		}

		return false;
	}, [features]);
};
