import { Typography } from '@mms/mms-ui-library';

import { PopUpBody } from '../PopUpBody';

import { useAuth } from '@/context/AuthContext';
import { createPeriodInText, getDeleteDialogText } from '@/helpers/booking';
import { getDialogTextComponent } from '@/helpers/getDeleteDialogTextComponent';
import {
	getDeskName,
	getSpaceAbriviationBySeatId,
} from '@/pages/Booking/utils';
import { DeskBooking } from '@/types/Booking';

interface DeleteBookingPopupProps {
	bookingItem: DeskBooking;
}

export function DeleteDeskBookingPopupBody({
	bookingItem,
}: DeleteBookingPopupProps) {
	const { isBlock, workplaceNumber, workplaceId, weeksInInterval, dateList } =
		bookingItem;
	const { id } = useAuth();

	const diologComponent = getDialogTextComponent(bookingItem, id);

	return (
		<PopUpBody>
			<Typography variant="m-400" align="center">
				{getDeleteDialogText(bookingItem, id)}
			</Typography>
			{diologComponent && (
				<Typography variant="m-400" align="center">
					{diologComponent}
				</Typography>
			)}
			{!isBlock && (
				<>
					<Typography variant="m-600" align="center">
						{getDeskName(
							getSpaceAbriviationBySeatId(workplaceId),
							workplaceNumber
						)}
					</Typography>
					<Typography variant="m-600" align="center">
						{createPeriodInText(weeksInInterval, dateList)}
					</Typography>
				</>
			)}
		</PopUpBody>
	);
}
