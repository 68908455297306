import { PropsWithChildren } from 'react';

import { WORKPLACE_RANGES } from '../../constants';

import {
	MinskSpaceAbriviations,
	WARSAW_SPACE_ABRIVIATION,
} from '@/constants/booking';

import { MinskSpaceALayout } from './components/MinskSpaceALayout';
import { MinskSpaceBLayout } from './components/MinskSpaceBLayout';
import { MinskSpaceCLayout } from './components/MinskSpaceCLayout';
import { WarsawSpaceLayout } from './components/WarsawSpaceLayout';
import {
	DeskDirectionRotateDegree,
	OfficeDeskPosition,
	SeatGenerationConfig,
	WorkSpaceConfiguration,
} from './types';

const { minskAIds, minskBIds, minskCIds, minskDIds, warsawIds } =
	WORKPLACE_RANGES;

const generateSeat = (
	left: number,
	top: number,
	rotationDegree: number,
	displayNumber: number,
	id: number
): OfficeDeskPosition => ({
	id,
	left,
	top,
	displayNumber,
	rotationDegree,
});

class SpaceGenerator {
	seats: Array<OfficeDeskPosition> = [];

	initialId: number = 1;

	abriviation: string;

	constructor(initialId: number, abriviation: string) {
		this.seats = [];
		this.initialId = initialId;
		this.abriviation = abriviation;
	}

	generateSeats(
		configs: Array<SeatGenerationConfig>,
		layout: (props: PropsWithChildren) => React.ReactNode
	): WorkSpaceConfiguration {
		const { seats, initialId: startId, abriviation } = this;
		configs.forEach(({ func, paddings }) => {
			paddings.forEach((padding) => {
				seats.push(func(padding, seats.length + 1, startId + seats.length));
			});
		});

		return {
			SpaceLayout: layout,
			seats,
			spaceAbriviation: abriviation,
			walls: [],
		};
	}
}

const seatHorizontalPairsAlternation = (displayNumber: number) => {
	if (displayNumber % 2 === 0) {
		return DeskDirectionRotateDegree.Left;
	}

	return DeskDirectionRotateDegree.Right;
};

const seatVerticaltPairsAlternation = (displayNumber: number) => {
	if (displayNumber % 2 === 0) {
		return DeskDirectionRotateDegree.Top;
	}

	return DeskDirectionRotateDegree.Bottom;
};

const minskSpaceAFirstRow = (left: number, displayNumber: number, id: number) =>
	generateSeat(
		left,
		27,
		seatHorizontalPairsAlternation(displayNumber),
		displayNumber,
		id
	);

export const generateMinskSpaceA = () => {
	const minskSpaceAGenerator = new SpaceGenerator(
		minskAIds[0],
		MinskSpaceAbriviations.A
	);

	return minskSpaceAGenerator.generateSeats(
		[
			{
				paddings: [15, 62, 129, 176, 471],
				func: minskSpaceAFirstRow,
			},
		],
		MinskSpaceALayout
	);
};

const minskSpaceBFirstColumn = (
	top: number,
	displayNumber: number,
	id: number
) => generateSeat(88, top, DeskDirectionRotateDegree.Right, displayNumber, id);

export const generateMinskSpaceB = () => {
	const minskSpaceBGenerator = new SpaceGenerator(
		minskBIds[0],
		MinskSpaceAbriviations.B
	);

	return minskSpaceBGenerator.generateSeats(
		[
			{
				paddings: [42, 88 + 16, 88 + 92, 88 + 154, 88 + 230],
				func: minskSpaceBFirstColumn,
			},
		],
		MinskSpaceBLayout
	);
};

const minskSpaceCFirstRow = (left: number, displayNumber: number, id: number) =>
	generateSeat(
		left,
		167,
		seatHorizontalPairsAlternation(displayNumber),
		displayNumber,
		id
	);

export const generateMinskSpaceC = () => {
	const minskSpaceCGenerator = new SpaceGenerator(
		minskCIds[0],
		MinskSpaceAbriviations.C
	);

	return minskSpaceCGenerator.generateSeats(
		[
			{
				paddings: [43, 90, 167, 214, 292],
				func: minskSpaceCFirstRow,
			},
		],
		MinskSpaceCLayout
	);
};

export const generateMinskSpaceD = () => {
	const minskSpaceDGenerator = new SpaceGenerator(
		minskDIds[0],
		MinskSpaceAbriviations.D
	);

	return minskSpaceDGenerator.generateSeats(
		[
			{
				paddings: [42, 92, 172, 222, 302],
				func: minskSpaceCFirstRow,
			},
		],
		MinskSpaceCLayout
	);
};

const warsawLastColumn = (top: number, displayNumber: number, id: number) =>
	generateSeat(
		330,
		top,
		seatVerticaltPairsAlternation(displayNumber),
		displayNumber,
		id
	);
const warsawTopRow = (left: number, displayNumber: number, id: number) =>
	generateSeat(left, 40, DeskDirectionRotateDegree.Top, displayNumber, id);

const warsawBottomRow =
	(alternationKoef = 1) =>
	(left: number, displayNumber: number, id: number) =>
		generateSeat(
			left,
			214,
			seatHorizontalPairsAlternation(displayNumber + alternationKoef),
			displayNumber,
			id
		);

export const generateWarasawSpace = () => {
	const warsawGenerator = new SpaceGenerator(
		warsawIds[0],
		WARSAW_SPACE_ABRIVIATION
	);

	return warsawGenerator.generateSeats(
		[
			{
				paddings: [80, 150, 228],
				func: warsawLastColumn,
			},
			{
				paddings: [230],
				func: warsawBottomRow(2),
			},
			{
				paddings: [210],
				func: warsawTopRow,
			},

			{
				paddings: [160, 70, 0],
				func: warsawBottomRow(),
			},
			{
				paddings: [70],
				func: warsawTopRow,
			},
		],
		WarsawSpaceLayout
	);
};
