import { useMemo } from 'react';

import { MINSK_SPACES, WARSAW_SPACE } from '../constants';
import { WorkSpaceConfiguration } from '../types';

import { FeatureName } from '@/constants/featureNames';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { areMeetingRoomsOpen } from '@/pages/Booking/utils';
import { LocationTypeId, OfficeType } from '@/types/Configuration';

interface UseWorkplaceConfigurationOptions {
	office: OfficeType;
	spaceId: number | null;
}

export const useWorkplaceConfiguration = ({
	office,
	spaceId,
}: UseWorkplaceConfigurationOptions): {
	config: WorkSpaceConfiguration | null;
	isAvailable: boolean;
} => {
	const isMinskOfficeAvailable = useFeatureFlag(
		FeatureName.MinskOfficeAvailable
	);

	const config = useMemo(() => {
		if (areMeetingRoomsOpen(spaceId)) {
			return {
				config: null,
				isAvailable: true,
			};
		}

		if (
			office.location === LocationTypeId.Minsk &&
			spaceId &&
			MINSK_SPACES[spaceId]
		) {
			return {
				config: MINSK_SPACES[spaceId],
				isAvailable: isMinskOfficeAvailable,
			};
		}

		if (office.location === LocationTypeId.Warsaw) {
			return {
				config: WARSAW_SPACE,
				isAvailable: true,
			};
		}

		return {
			config: WARSAW_SPACE,
			isAvailable: true,
		};
	}, [office, isMinskOfficeAvailable, spaceId]);

	return config;
};
