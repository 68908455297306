import axios from 'axios';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_COLLISIONS_API_ENDPOINT,
	BookingQueriesKeys,
} from '@/constants/index';
import { useToast } from '@/context/index';
import { SelectedDesk } from '@/context/types';
import {
	getDateAsResponseType,
	getTextFromMultipleDate,
} from '@/helpers/booking';
import { createToast } from '@/helpers/createToast';

interface UseCheckCollisionsOptions {
	desk: SelectedDesk | null;
	fromDate: Moment | null;
	toDate: Moment | null;
	weeksInterval: number;
	officeId: string;
	userId: number;
	isBlockedDesk: boolean;
	onDeskCollisionExist?: () => void;
	onDeskCollisionAbsent?: () => void;
}

export const useCheckDeskCollisions = ({
	desk,
	fromDate,
	toDate,
	weeksInterval,
	officeId,
	userId,
	isBlockedDesk,
	onDeskCollisionAbsent,
	onDeskCollisionExist,
}: UseCheckCollisionsOptions) => {
	// Prevent check for collisions on office change
	const [prevOfficeId, setPrevOfficeId] = useState(officeId);
	const toast = useToast();

	useEffect(() => {
		setPrevOfficeId(officeId);
	}, [officeId]);

	const handleDeskCollisionExist = () => {
		if (typeof onDeskCollisionExist === 'function') {
			onDeskCollisionExist();
		}
	};

	const handleDeskCollisionAbsent = () => {
		if (typeof onDeskCollisionAbsent === 'function') {
			onDeskCollisionAbsent();
		}
	};

	return useQuery({
		queryKey: [
			BookingQueriesKeys.collisions,
			desk?.id,
			fromDate,
			toDate,
			weeksInterval,
			officeId,
			userId,
		],
		queryFn: async () => {
			if (desk && desk.id > 0 && prevOfficeId === officeId) {
				try {
					const response = await axios.get(GET_COLLISIONS_API_ENDPOINT, {
						params: {
							officeId,
							workplaceId: desk.id,
							fromDate: getDateAsResponseType(fromDate as Moment),
							toDate: getDateAsResponseType(toDate as Moment),
							weeksInterval,
							userId,
						},
					});

					const seatName = `${desk.abriviation} - ${desk?.displayNumber}`;

					if (response.data.length > 0) {
						if (isBlockedDesk) {
							toast.open(
								createToast(
									NotificationType.INFO,
									null,
									<p>
										You cannot book it, because the workplace <b>{seatName} </b>
										is blocked
									</p>
								)
							);
						} else {
							toast.open(
								createToast(
									NotificationType.INFO,
									null,
									<p>
										You cannot book it, because the workplace {seatName} is
										booked for the following period:{' '}
										{getTextFromMultipleDate(response.data)}
									</p>
								)
							);
						}

						handleDeskCollisionExist();

						return true;
					}
				} catch {
					handleDeskCollisionExist();

					return true;
				}

				handleDeskCollisionAbsent();

				return false;
			}

			handleDeskCollisionAbsent();

			return false;
		},
		keepPreviousData: true,
		enabled: Boolean(desk?.id) && Boolean(fromDate) && Boolean(toDate),
		refetchOnWindowFocus: false,
	});
};
