import { Tabs } from '@mms/mms-ui-library';

import { bookingBlockTabs } from '../../../../constants';

import { TabsWrapper } from './styled';
import { BookingBlockTabsProps } from './types';

export function BookingBlockTabs({
	activeTab,
	onChange,
}: BookingBlockTabsProps) {
	return (
		<TabsWrapper>
			<Tabs
				fullWidth={false}
				type="default"
				needBottomLine={false}
				activeTab={activeTab}
				onChangeTab={onChange}
				data={bookingBlockTabs}
			/>
		</TabsWrapper>
	);
}
