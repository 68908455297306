import { Switch, Typography } from '@mms/mms-ui-library';
import { memo } from 'react';

import { AllDaySwitchWrapper } from './styled';
import { AllDaySwitchProps } from './types';

const ALL_DAYS_SWITCH_NAME = 'all-day-switch';

function AllDaySwitchInner({
	checked,
	isDisabled,
	handleChange,
}: AllDaySwitchProps) {
	return (
		<AllDaySwitchWrapper htmlFor={ALL_DAYS_SWITCH_NAME}>
			<Switch
				checked={checked && !isDisabled}
				onChange={handleChange}
				name={ALL_DAYS_SWITCH_NAME}
				disabled={isDisabled}
				id={ALL_DAYS_SWITCH_NAME}
			/>
			<Typography variant="s-400">All day</Typography>
		</AllDaySwitchWrapper>
	);
}

export const AllDaySwitch = memo(AllDaySwitchInner);
