import { useTheme } from '@emotion/react';
import { Typography } from '@mms/mms-ui-library';

import { useTimelineContext } from '../../TimelineContext';

import { TimelineSectionSkeleton, TimelineSectionWrapper } from './styles';
import { TimelineSectionProps } from './types';

export function TimelineSection({
	id,
	name,
	isSelected,
	onSectionSelection,
}: TimelineSectionProps) {
	const theme = useTheme();

	const { showLoader, isPeriodSelectionDisabled } = useTimelineContext();

	const onTimelineSectionClick = () => {
		if (
			typeof onSectionSelection === 'function' &&
			!showLoader &&
			!isPeriodSelectionDisabled
		) {
			onSectionSelection({ id, name });
		}
	};

	return (
		<TimelineSectionWrapper
			onClick={onTimelineSectionClick}
			isDisabled={showLoader || isPeriodSelectionDisabled}
			isSelected={isSelected && !showLoader && !isPeriodSelectionDisabled}
		>
			{!showLoader ? (
				<Typography color={theme.palette['base-6']} variant="s-400">
					{name}
				</Typography>
			) : (
				<TimelineSectionSkeleton animation="wave" />
			)}
		</TimelineSectionWrapper>
	);
}
