import { BUSINESS_START_HOUR_STRING } from '../constants';

const MILLISECONDS_TO_HOURS = 1000 * 60 * 60;

export function calculatePosX(
	startDateTime: string,
	referenceTime: string = BUSINESS_START_HOUR_STRING
): number {
	const referenceDateTime = new Date(
		`${startDateTime.split('T')[0]}T${referenceTime}`
	);
	const startTime = new Date(startDateTime);

	const timeDifference = startTime.getTime() - referenceDateTime.getTime();
	const posX = timeDifference / MILLISECONDS_TO_HOURS;

	return posX;
}
