import { ChangeEvent, useCallback, useMemo } from 'react';

import {
	ALL_HOURS_START,
	BUSSINESS_HOURS_START,
	SKELETON_RESPONSE,
	TimelinePeriod,
} from './constants';
import { TimelineTable, TimelineTableWrapper, TimelineWrapper } from './styles';
import { TimelineProvider } from './TimelineContext';
import { TimelineControls } from './TimelineControls';
import { TimelineGrid } from './TimelineGrid';
import { TimelineSectionsColumn } from './TimelineSectionsColumn';
import { Section, TimelineProps } from './types';
import { parseCellIndexPeriod } from './utils/parseCellIndexPeriod';
import { parseDatePeriod } from './utils/parsePeriod';

export function Timeline({
	data = [],
	selectedTimePeriod,
	onTimePeriodSelection,
	selectedSectionId,
	onSectionSelection,
	selectedDate,
	onGoBackwardDate,
	onGoForwardDate,
	onGoTodayDate,
	isWorkingHoursOnly = true,
	onIsWorkingHoursOnlyChange,
	selectionPeriod = TimelinePeriod.HOUR_PERIOD,
	selectFromPeriodStart = false,
	isPeriodSelectionDisabled = false,
	hasCurrentTimeIndicator = true,
	showLoader = false,
	scrollToCurrentTime,
	scrollToSelectedPeriod,
}: TimelineProps) {
	const baseHour = isWorkingHoursOnly ? BUSSINESS_HOURS_START : ALL_HOURS_START;

	const parsedSelectedPeriod = useMemo(() => {
		if (!selectedTimePeriod) {
			return null;
		}

		return parseDatePeriod(selectedTimePeriod, baseHour);
	}, [selectedTimePeriod, baseHour]);

	const dataToShow = useMemo(() => {
		if (showLoader) {
			return SKELETON_RESPONSE;
		}

		return data;
	}, [showLoader, data]);

	const sections = useMemo(
		() =>
			dataToShow.map((item) => ({
				id: item.meetingRoomId,
				name: item.name,
			})),
		[data]
	);

	const handleTimePeriodSelection = useCallback(
		(section: Section, from: number, to: number) => {
			if (typeof onTimePeriodSelection === 'function') {
				const { from: parsedFrom, to: parsedTo } = parseCellIndexPeriod(
					{
						from,
						to,
						isOverlaped: false,
					},
					baseHour
				);

				onTimePeriodSelection(section, parsedFrom, parsedTo);
			}
		},
		[baseHour, onTimePeriodSelection]
	);

	const handleWorkingHoursChange = useCallback(
		(_: ChangeEvent<HTMLInputElement>, { checked }: { checked: boolean }) => {
			if (typeof onIsWorkingHoursOnlyChange === 'function') {
				onIsWorkingHoursOnlyChange(checked);
			}
		},
		[onIsWorkingHoursOnlyChange]
	);

	return (
		<TimelineWrapper>
			<TimelineControls
				selectedDate={selectedDate}
				isWorkingHoursOnly={isWorkingHoursOnly}
				onGoBackwardDate={onGoBackwardDate}
				onGoForwardDate={onGoForwardDate}
				onGoTodayDate={onGoTodayDate}
				onWorkingHoursChange={handleWorkingHoursChange}
			/>
			<TimelineTableWrapper scroll="both" maxHeight="100%" maxWidth="100%">
				<TimelineTable isPeriodSelectionDisabled={isPeriodSelectionDisabled}>
					<TimelineProvider
						isWorkingHoursOnly={isWorkingHoursOnly}
						selectFromPeriodStart={selectFromPeriodStart}
						selectionPeriod={selectionPeriod}
						isPeriodSelectionDisabled={isPeriodSelectionDisabled}
						hasCurrentTimeIndicator={hasCurrentTimeIndicator}
						showLoader={showLoader}
						scrollToCurrentTime={scrollToCurrentTime}
						scrollToSelectedPeriod={scrollToSelectedPeriod}
					>
						<TimelineSectionsColumn
							selectedSectionId={selectedSectionId}
							onSectionSelection={onSectionSelection}
							sections={sections}
						/>
						<TimelineGrid
							data={dataToShow}
							selectedDate={selectedDate}
							selectedSectionId={selectedSectionId}
							selectedPeriod={parsedSelectedPeriod}
							onPeriodSelect={handleTimePeriodSelection}
						/>
					</TimelineProvider>
				</TimelineTable>
			</TimelineTableWrapper>
		</TimelineWrapper>
	);
}
