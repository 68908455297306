import styled from '@emotion/styled';
import { getScrollbarStyles } from '@mms/mms-ui-library';

import { CELL_WIDTH, SROLLBAR_SIZE } from '../constants';

import { BorderRadiuses } from '@/constants/borderRadiuses';

const TIMELINE_GRID_BORDER_MARGIN = 2;

export const TimelineGridWrapper = styled('div')<{ cellsAmount: number }>(
	({ theme, cellsAmount }) => ({
		position: 'relative',
		width: cellsAmount * CELL_WIDTH + TIMELINE_GRID_BORDER_MARGIN,

		border: `1px solid ${theme.palette['primary-4']}`,
		borderTopRightRadius: BorderRadiuses.xs2,
		borderBottomRightRadius: BorderRadiuses.xs2,
		...getScrollbarStyles(theme, SROLLBAR_SIZE, 'horizontal'),
	})
);

export const TimelineGridCellsWrapper = styled('div')({
	position: 'relative',
});
