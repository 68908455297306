import { memo } from 'react';

import { ProtectedView } from '@/components/ProtectedView';
import { managerRoles } from '@/constants/index';
import { useBookingContext } from '@/context/Booking';

import { BookingCalendar } from './components/BookingCalendar';
import { BookingControls } from './components/BookingControls';
import { DatePickers } from './components/DatePickers';
import { TimeSeletors } from './components/TimeSelectors';
import { BookingUsersAutoselect } from './components/UsersAutoselect';
import { BookingFormWrapper, DatePickersWrapper } from './styled';

function BookingFormInner() {
	const { areMeetingRoomsSelected } = useBookingContext();

	return (
		<BookingFormWrapper>
			<BookingCalendar />
			<ProtectedView roles={managerRoles}>
				<BookingUsersAutoselect />
			</ProtectedView>
			<DatePickersWrapper>
				<DatePickers />
			</DatePickersWrapper>
			{areMeetingRoomsSelected && <TimeSeletors />}
			<BookingControls />
		</BookingFormWrapper>
	);
}

export const BookingForm = memo(BookingFormInner);
