export const FILE_EXTENSIONS = {
	csv: 'csv',
	xlsx: 'xlsx',
	excel: 'excel',
	pdf: 'pdf',
	word: 'word',
};

export const FILE_EXTENSION_MAPPING = {
	csv: 'csv',
	excel: 'xlsx',
	pdf: 'pdf',
	word: 'word',
};
