import { styled } from '@mui/material';

export const TabsWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	overflow: 'hidden',
	position: 'relative',
	flexShrink: 0,

	'&:after': {
		content: '""',
		position: 'absolute',
		width: '100%',
		height: '1px',
		background: theme.palette['primary-4'],
		bottom: 0,
		left: 0,
	},
}));
