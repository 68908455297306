import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

import { CELL_HEIGHT, CELL_WIDTH } from '../constants';

export const TimelineRowWrapper = styled('div')<{
	cellsAmount: number;
}>(({ theme, cellsAmount }) => ({
	position: 'relative',
	display: 'grid',
	alignItems: 'center',

	gridTemplateColumns: `repeat(${cellsAmount},${CELL_WIDTH}px)`,
	borderTop: `1px solid ${theme.palette['primary-3']}`,
	width: cellsAmount * CELL_WIDTH,
	height: CELL_HEIGHT,
	overflow: 'hidden',
}));

export const TimelineRowSkeleton = styled(Skeleton)<{ cellsAmount: number }>(
	({ theme, cellsAmount }) => ({
		marginLeft: theme.spaces.s,
		height: CELL_HEIGHT,
		width: cellsAmount * CELL_WIDTH - theme.spaces.m,
	})
);
