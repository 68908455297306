import styled from '@emotion/styled';
import { Scrollbar } from '@mms/mms-ui-library';

export const WorkplaceWrapper = styled(Scrollbar)(() => ({
	width: '100%',
	height: '100%',
}));

export const WorkplaceLoaderWrapper = styled('div')(() => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const TitleWrapper = styled('div')(() => ({}));

export const SpaceLayoutWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	height: '100%',
	alignItems: 'center',
	width: 'fit-content',
	minWidth: '100%',
	minHeight: 'min-content',
}));
