import { TimelinePeriod } from '../constants';
import { SelectedTimePeriod } from '../types';

import { ALL_DAY_TIME } from '@/constants/booking';

import { convertDateToTimelineHours } from './convertDateToTimelineHours';
import { convertISOToTimelineHours } from './convertISOToTimelineHours';
import { parseUTC } from './parseUTC';

export const parseDatePeriod = (
	datePeriod: SelectedTimePeriod<Date>,
	baseHour: number
) => {
	const { from, to, isOverlaped } = datePeriod;

	let toHours = convertDateToTimelineHours(to, baseHour);

	if (to.getDate() > from.getDate()) {
		if (to.getHours() === 0) {
			toHours = TimelinePeriod.FULL_DAY - baseHour;
		}
	}

	return {
		from: convertDateToTimelineHours(from, baseHour),
		to: toHours,
		isOverlaped,
	};
};

export const parseISOPeriod = (
	ISOPeriod: SelectedTimePeriod<string>,
	baseHour: number
) => {
	const { from, to, isOverlaped } = ISOPeriod;

	const [hours, minutes, seconds] = ALL_DAY_TIME.to.split(':');

	let toHours = convertISOToTimelineHours(to, baseHour);

	const toDate = new Date(parseUTC(to));

	const isAllDayTime =
		toDate.getHours() === Number(hours) &&
		toDate.getMinutes() === Number(minutes) &&
		toDate.getSeconds() === Number(seconds);

	if (isAllDayTime) {
		toHours = TimelinePeriod.FULL_DAY - baseHour;
	}

	return {
		from: convertISOToTimelineHours(from, baseHour),
		to: toHours,
		isOverlaped,
	};
};
