import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import { switchGridAreas } from '../constants';

import { PLAIN_TEXT_FONT_SIZE } from '@/constants/commonStyles';

export const StyledAccordion = styled(Accordion)({
	gridArea: switchGridAreas.repetativeAccordion,

	backgroundColor: 'transparent',
	'& .MuiPaper-root, & .MuiAccordionSummary-content': {
		margin: 0,
	},
	'& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
		padding: 0,
	},
	'&::before': {
		display: 'none',
	},
});

export const StyledAccordionSummary = styled(AccordionSummary)({
	minHeight: 0,
});
export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	gap: theme.spacing(1),
	userSelect: 'none',

	'.MuiTypography-root': {
		fontSize: PLAIN_TEXT_FONT_SIZE,
	},
}));

export const RepetativeSwitchContainer = styled('label')(({ theme }) => ({
	gridArea: switchGridAreas.repetativeSwitch,
	display: 'flex',
	gap: theme.spaces.s,
	alignItems: 'center',
}));
