import { SearchIcon, Tooltip } from '@mms/mms-ui-library';
import { memo } from 'react';

import { Square } from '@/components/Square';
import { COLORS, COLORS_ENUM } from '@/constants/index';
import { useBookingContext } from '@/context/Booking';
import { useBookingMapContext } from '@/pages/Booking/context/BookingMapContext';
import { LocationTypeId } from '@/types/Configuration';

import {
	LegendWrapper,
	ColorTooltipTextWrapper,
	ControlsWrapper,
	MapButton,
} from './styled';

const MAP_BUTTON_TEXT = 'View Map';

function WorkplaceControlsInner({ showLegend }: { showLegend: boolean }) {
	const { setIsMapOpen } = useBookingMapContext();
	const { office } = useBookingContext();

	const handleMapOpen = () => {
		setIsMapOpen(true);
	};

	const showButton = office.location === LocationTypeId.Minsk;

	return (
		<ControlsWrapper hasLegend={showLegend}>
			{showLegend && (
				<LegendWrapper>
					{COLORS.map((color) => (
						<Tooltip
							key={color}
							text={
								<ColorTooltipTextWrapper>
									{color === COLORS_ENUM.Selected ? 'Your desk' : color}
								</ColorTooltipTextWrapper>
							}
						>
							<Square type={color} side={24} />
						</Tooltip>
					))}
				</LegendWrapper>
			)}
			{showButton && (
				<MapButton
					type="button"
					fullWidth={false}
					onClick={handleMapOpen}
					typography="xs-500"
				>
					{MAP_BUTTON_TEXT} <SearchIcon />
				</MapButton>
			)}
		</ControlsWrapper>
	);
}

export const WorkplaceControls = memo(WorkplaceControlsInner);
