import { useTheme } from '@emotion/react';
import { ClockIcon, LoopIcon, Tooltip } from '@mms/mms-ui-library';
import { memo, useMemo, useRef } from 'react';

import {
	BUSINESS_HOURS_CELLS_AMOUNT,
	BUSINESS_START_HOUR_STRING,
	START_HOUR_STRING,
	TimelinePeriod,
} from '../constants';
import { useAnyElementIsCut } from '../hooks/useAnyElementIsCut';
import { useTimelineContext } from '../TimelineContext';
import { BookedData } from '../types';
import { calculateDuration } from '../utils/calculateDuration';
import { calculatePosX } from '../utils/calculatePosX';

import { getFullName } from '@/helpers/formatName';

import { FULL_DAY_PERIOD_TEXT } from './constants';
import {
	BookedLabelWrapper,
	BookedTimePeriod,
	BookedTimePeriodRow,
	ClockIconWrapper,
	FixedTypography,
	LoopIconWrapper,
} from './styles';
import { TooltipContent } from './TooltipContent';
import { getXPos, getWidth, formatTimeInterval } from './utils';

function BookedLabelInner({
	fromDateTime,
	toDateTime,
	name,
	lastName,
	isRepetitive,
}: BookedData) {
	const { palette } = useTheme();

	const fullNameTextRef = useRef<HTMLSpanElement>(null);
	const timeTextRef = useRef<HTMLSpanElement>(null);

	const { isWorkingHoursOnly, isPeriodSelectionDisabled, baseHour } =
		useTimelineContext();

	const { isCut } = useAnyElementIsCut([fullNameTextRef, timeTextRef]);

	const duration = useMemo(
		() => calculateDuration(fromDateTime, toDateTime, baseHour),
		[fromDateTime, toDateTime, baseHour]
	);

	const posX = useMemo(
		() =>
			calculatePosX(
				fromDateTime,
				isWorkingHoursOnly ? BUSINESS_START_HOUR_STRING : START_HOUR_STRING
			),
		[fromDateTime, isWorkingHoursOnly]
	);

	const OUT_OF_RANGE = posX > BUSINESS_HOURS_CELLS_AMOUNT;

	const IS_FULL_DAY = duration === TimelinePeriod.FULL_DAY;
	const IS_QUATER_PERIOD = duration <= TimelinePeriod.QUATER_PERIOD;
	const IS_PERIOD_WITH_DETAILS =
		duration < TimelinePeriod.HALF_AND_QUATER_PERIOD;

	const IS_TOOLTIP_OPEN = posX < 0 || isCut || IS_PERIOD_WITH_DETAILS;

	const fullName = getFullName(name, lastName);

	const timePeriod = IS_FULL_DAY
		? FULL_DAY_PERIOD_TEXT
		: formatTimeInterval(fromDateTime, toDateTime);

	if (isWorkingHoursOnly && OUT_OF_RANGE) {
		return null;
	}

	return (
		<Tooltip
			open={IS_TOOLTIP_OPEN}
			text={<TooltipContent fullName={fullName} timePeriod={timePeriod} />}
		>
			<BookedLabelWrapper
				isDisabled={isPeriodSelectionDisabled}
				isQuaterPeriod={IS_QUATER_PERIOD}
				posX={getXPos(posX)}
				width={getWidth(duration)}
			>
				<FixedTypography
					ref={fullNameTextRef}
					color={palette['base-6']}
					variant="xs-400"
				>
					{IS_PERIOD_WITH_DETAILS ? '' : fullName}
				</FixedTypography>
				<BookedTimePeriod>
					<BookedTimePeriodRow>
						{!IS_PERIOD_WITH_DETAILS && (
							<ClockIconWrapper>
								<ClockIcon />
							</ClockIconWrapper>
						)}
						<FixedTypography
							ref={timeTextRef}
							color={palette['base-6']}
							variant="xs-400"
						>
							{IS_PERIOD_WITH_DETAILS ? '' : timePeriod}
						</FixedTypography>
					</BookedTimePeriodRow>
					{isRepetitive && (
						<LoopIconWrapper isQuaterPeriod={IS_QUATER_PERIOD}>
							<LoopIcon />
						</LoopIconWrapper>
					)}
				</BookedTimePeriod>
			</BookedLabelWrapper>
		</Tooltip>
	);
}

export const BookedLabel = memo(BookedLabelInner);
