import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';

import {
	NotificationType,
	SOMETHING_WENT_WRONG,
	HttpStatuses,
	createMeetingRoomDeleteBookingApiUrl,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';

import { DeleteMeetingRoomVariables } from './types';

export const useDeleteMeetingRoomBooking = (
	options: UseMutationOptions<void, AxiosError, DeleteMeetingRoomVariables>,
	toast: ToastContextValues
) =>
	useMutation<void, AxiosError, DeleteMeetingRoomVariables>({
		...options,
		mutationFn: ({ entryId }) =>
			axios
				.delete(createMeetingRoomDeleteBookingApiUrl(entryId))
				.then(() => {})
				.catch((error: unknown) => {
					if (
						error instanceof AxiosError &&
						error.response?.status !== HttpStatuses.NotFound
					) {
						toast.open(
							createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
						);
					}
				}),
	});
