export const switchGridAreas = {
	repetativeSwitch: 'repeetative',
	allDaySwitch: 'allDay',
	repetativeAccordion: 'repeetativeAccordion',
};

const BOOKING_POPUP_WIDTH = 410;

export const BOOKING_POPUP_STYLES_CONFIG = {
	container: {
		width: BOOKING_POPUP_WIDTH,
	},
};
