import { RefObject, useEffect } from 'react';

import { BUSINESS_HOURS_END, CELL_WIDTH } from '../constants';
import { useTimelineContext } from '../TimelineContext';
import { SelectedTimePeriod, TimelineScroll } from '../types';
import { mapScrollPositionToScrollOffset } from '../utils/mapScrollPositionToScrollOffset';
import { parseDatePeriod } from '../utils/parsePeriod';

export const useScrollTimelineToCurrentTime = (
	timelineRef: RefObject<HTMLDivElement>,
	selectedPeriod: SelectedTimePeriod<number> | null,
	scroll?: TimelineScroll
) => {
	const { isWorkingHoursOnly, baseHour } = useTimelineContext();

	const now = new Date();
	const hours = now.getHours();

	const IS_IN_WORKING_RANGE = !isWorkingHoursOnly || hours < BUSINESS_HOURS_END;

	useEffect(() => {
		const SHOULD_SCROLL_TO_CURRENT_TIME =
			scroll && IS_IN_WORKING_RANGE && !selectedPeriod && timelineRef.current;

		if (SHOULD_SCROLL_TO_CURRENT_TIME) {
			const { position, behavior } = scroll;

			const timelineWidth = timelineRef.current?.clientWidth;

			const offsetInCells = parseDatePeriod(
				{ from: now, to: now, isOverlaped: false },
				baseHour
			);

			const scrollOffset =
				offsetInCells.from * CELL_WIDTH -
				mapScrollPositionToScrollOffset(position || 'center', timelineWidth);

			timelineRef.current?.scrollTo({
				left: scrollOffset,
				behavior,
			});
		}
	}, [timelineRef.current]);
};
