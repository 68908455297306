import styled from '@emotion/styled';

const POPUP_BODY_MAX_WIDTH = 288;

export const BodyWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spaces.xs,
	maxWidth: POPUP_BODY_MAX_WIDTH,
	alignItems: 'center',

	'& > span': {
		textAlign: 'center',
	},
}));

export const TextWrapper = styled('div')({
	display: 'flex',
	justifyContent: 'center',
});
