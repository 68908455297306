export enum NotificationType {
	WorkplaceBookingCancelled = 'WorkplaceBookingCancelled',
	MeetingRoomBookingCancelled = 'MeetingRoomBookingCancelled',
	TimeTrackReportCreated = 'TimeTrackReportCreated',
	TimeTrackReportGenerating = 'TimeTrackReportGenerating',
	SameTimeTrackReportGenerating = 'SameTimeTrackReportGenerating',
	TimeTrackReportGenerationFailed = 'TimeTrackReportGenerationFailed',
}

export enum DocumentFileType {
	Word = 'Word',
	Excel = 'Excel',
	Pdf = 'Pdf',
}

interface BaseNotificationFields {
	id: number;
	userId: number;
	sent: boolean;
	read: boolean;
	isDeleted: boolean;
	notificationType: NotificationType;
}

type ParsedNotification = BaseNotificationFields;

export interface RawUserNotification extends BaseNotificationFields {
	outputJson: string;
}

export interface TimeTrackReportCreatedNotification
	extends BaseNotificationFields {
	notificationType: NotificationType.TimeTrackReportCreated;
	ReportName: string;
	ReportFileId: string;
	DocumentType: DocumentFileType;
	SizeInKilobytes: number;
}

export interface WorkplaceBookingCancelledNotification
	extends ParsedNotification {
	notificationType: NotificationType.WorkplaceBookingCancelled;
	DateList: string[];
	WorkplaceNumber: number;
	WorkplaceId: number;
	WorkplaceOfficeId: number;
	BookingEntryId: number;
	IsBlock: boolean;
	Message: string;
}

export interface MeetingRoomBookingCancelledNotification
	extends ParsedNotification {
	notificationType: NotificationType.MeetingRoomBookingCancelled;
	DateList: string[];
	MeetingRoomId: number;
	MeetingRoomName: string;
	MeetingRoomEntryId: number;
	FromTime: string;
	ToTime: string;
	Message: string;
}

export interface SameTimeTrackReportGeneratingNotification
	extends ParsedNotification {
	notificationType: NotificationType.SameTimeTrackReportGenerating;
	ReportName: string;
	DocumentType: DocumentFileType;
}

export interface TimeTrackReportGeneratingNotification
	extends ParsedNotification {
	notificationType: NotificationType.TimeTrackReportGenerating;
	ReportName: string;
	DocumentType: DocumentFileType;
}

export interface TimeTrackReportFailureNotification extends ParsedNotification {
	notificationType: NotificationType.TimeTrackReportGenerationFailed;
	ReportName: string;
	DocumentType: DocumentFileType;
}

export type UserNotification =
	| TimeTrackReportCreatedNotification
	| WorkplaceBookingCancelledNotification
	| MeetingRoomBookingCancelledNotification
	| TimeTrackReportGeneratingNotification
	| TimeTrackReportFailureNotification;
