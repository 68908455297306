import { useEffect, useRef } from 'react';

import { useTimelineContext } from '../TimelineContext';

import { SelectionBoxWrapper } from './styles';
import { SelectionBoxProps } from './types';
import { calculateSelectedRangeStyle } from './utils';

export function SelectionBox({
	selectedPeriod,
	selectedSectionId,
}: SelectionBoxProps) {
	const { scrollToSelectedPeriod, cellsAmount } = useTimelineContext();

	const selectionRef = useRef<HTMLDivElement>(null);

	const IS_OUT_OF_LIMIT = selectedPeriod.to > cellsAmount;

	useEffect(() => {
		if (scrollToSelectedPeriod) {
			selectionRef.current?.scrollIntoView({
				behavior: scrollToSelectedPeriod?.behavior,
				block: 'nearest',
			});
		}
	}, [selectedPeriod]);

	const selectedRangeStyle = calculateSelectedRangeStyle(
		selectedPeriod.from,
		Math.min(selectedPeriod.to, cellsAmount)
	);

	return (
		<SelectionBoxWrapper
			isOutOfLimit={IS_OUT_OF_LIMIT}
			sectionId={selectedSectionId}
			ref={selectionRef}
			isOverlaped={selectedPeriod.isOverlaped}
			{...selectedRangeStyle}
		/>
	);
}
