import { memo, useEffect, useState } from 'react';

import { CELL_WIDTH } from '../constants';
import { useTimelineContext } from '../TimelineContext';
import { parseDatePeriod } from '../utils/parsePeriod';

import { CurrentTimeIndicatorLine } from './styles';

const TIME_INDICATOR_UPDATE_INTERVAL = 60000;

function CurrentTimeIndicatorInner() {
	const { baseHour, cellsAmount } = useTimelineContext();

	const [currentTime, setCurrentTime] = useState(new Date());

	const currentHourPeriod = parseDatePeriod(
		{
			isOverlaped: false,
			from: currentTime,
			to: currentTime,
		},
		baseHour
	);

	const IS_CURRENT_TIME_INDICATOR_VISIBLE =
		currentHourPeriod.from < cellsAmount;

	useEffect(() => {
		if (IS_CURRENT_TIME_INDICATOR_VISIBLE) {
			const intervalId = setInterval(() => {
				setCurrentTime(new Date());
			}, TIME_INDICATOR_UPDATE_INTERVAL);

			return () => clearInterval(intervalId);
		}
	}, []);

	if (!IS_CURRENT_TIME_INDICATOR_VISIBLE) {
		return null;
	}

	return (
		<CurrentTimeIndicatorLine posX={currentHourPeriod.from * CELL_WIDTH} />
	);
}

export const CurrentTimeIndicator = memo(CurrentTimeIndicatorInner);
