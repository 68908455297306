import { useState } from 'react';

import { extractHighlightedDaysFromResponse } from '../../utils';

import { TabPanel } from '@/components/TabPanel';
import { Roles } from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { useBookingContext } from '@/context/Booking';
import { BookingList, BookingManagement } from '@/pages/Booking/components';
import { useGetBookings } from '@/queries/booking';

import { BookingBlockHeader } from './components/BookingBlockHeader';
import { BookingBlockTabs } from './components/BookingBlockTabs';
import {
	BookingsBlockContent,
	BookingsBody,
	BookingBlockWrapper,
} from './styled';

export function BookingBlock() {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const { id, role } = useAuth();
	const { setHighlightedDays, office } = useBookingContext();

	const { data, isSuccess, isLoading, isFetching } = useGetBookings(
		{
			userId: id,
			includeEmployee: role !== Roles.SA,
			officeId: office.id,
		},
		{
			onSuccess(successData) {
				setHighlightedDays(extractHighlightedDaysFromResponse(successData, id));
			},
		}
	);

	const showLoader = !isSuccess || isFetching || isLoading;

	return (
		<BookingBlockWrapper>
			<BookingBlockHeader />
			<BookingsBlockContent>
				<BookingsBody>
					<BookingBlockTabs
						activeTab={activeTabIndex}
						onChange={setActiveTabIndex}
					/>
					<TabPanel value={activeTabIndex} index={0}>
						<BookingManagement />
					</TabPanel>
					<TabPanel value={activeTabIndex} index={1}>
						<BookingList showLoader={showLoader} data={data} />
					</TabPanel>
				</BookingsBody>
			</BookingsBlockContent>
		</BookingBlockWrapper>
	);
}
