import { TrackedTimeType } from '../types';

import { DepartmentType } from '@/types/Configuration';

export const ALL_ID = 'All' as const;

export const extraFiltersBy = {
	All: 'All',
	Personal: 'Personal',
};

export const filterByPeriod = {
	weekFilter: 'This Week',
	monthFilter: 'This Month',
};

export const initialProjectsValue = {
	id: ALL_ID,
	name: 'All Projects',
} as const;

export const initialClientsValue = {
	id: ALL_ID,
	name: 'All Clients',
} as const;

export const initialUserFilterValue = {
	id: ALL_ID,
	name: 'All Users',
};

export const initialDepartmentsFilterValue: DepartmentType = {
	id: ALL_ID,
	shortName: 'All Departments',
	fullName: 'All Departments',
};

export const initialAvailabilityFilterValue = 'All';

export const initialEquipmentTypeFilterValue = {
	id: 'All',
	name: 'All Equipment',
};

export const initialEquipmentLocationFilterValue = {
	id: 'All',
	name: 'All Locations',
};

export const initialApproversFilterValue = {
	id: 'All',
	name: 'All Approvers',
};

export const DateFilterVariants = {
	CurrentMonth: 'Current month',
	LastMonth: 'Last month',
	WholeYear: 'Whole year',
};

export const dateFilterOptions = Object.values(DateFilterVariants);

export const NO_VALUE_FILTER_OPTION = {
	description: 'N/A',
	id: 'N/A',
	name: 'N/A',
	fullName: 'N/A',
	shortName: 'N/A',
	location: null,
};

export const BILLABLE_HOURS_FILTER = {
	id: TrackedTimeType.Billable,
	name: 'Billable',
};

export const FACT_HOURS_FILTER = {
	id: TrackedTimeType.Fact,
	name: 'Fact',
};

export const HOURS_FILTER_OPTIONS = [BILLABLE_HOURS_FILTER, FACT_HOURS_FILTER];
