import styled from '@emotion/styled';

const AFTER_HEIGHT = 1;

export const TabsWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	overflow: 'hidden',
	position: 'relative',

	'&:after': {
		content: '""',
		position: 'absolute',
		width: '100%',
		height: AFTER_HEIGHT,
		background: theme.palette['primary-4'],
		bottom: 0,
		left: 0,
	},
}));
