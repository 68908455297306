import moment from 'moment';
import React from 'react';

import { BookingOfficesId } from '../../types';
import { areMeetingRoomsOpen } from '../../utils';

import { WarningIcon } from '@/assets/icons/Warning';
import { useBookingContext } from '@/context/Booking';
import { useFilledDesksWithRangeDate } from '@/hooks/useFilledDesksWithRangeDate';
import { LocationTypeId } from '@/types/Configuration';

import { MeetingRoomsRenderer } from './components/MeetingRoomsRenderer';
import { SpacesTabs } from './components/SpacesTabs';
import { WorkplaceSpaceRenderer } from './components/WorkplaceSpaceRenderer';
import { DeskSize } from './components/WorkplaceSpaceRenderer/components/Desk/types';
import { MEETING_ROOMS_TAB_ID, OFFICE_CLOSED_MESSAGE } from './constants';
import { useWorkplaceConfiguration } from './hooks/useWorkplaceConfiguration';
import { WorkplaceControls } from './Legend';
import {
	BookingWorkplaceWrapper,
	OfficeWarningContainer,
	OfficeWarningMessageContainer,
	OfficeWarningTypograpphy,
	TitleWrapper,
} from './styled';

function BookingWorkplaceInner() {
	const {
		firstDay,
		lastDay,
		error,
		office,
		setWorkplaces,
		selectedSpaceId,
		setSelectedSpaceId,
		resetOnMeetingTabChange,
		areMeetingRoomsSelected,
	} = useBookingContext();

	const hasSpaces = (office.workspaces || []).length > 0;

	useFilledDesksWithRangeDate(
		moment(firstDay),
		moment(lastDay),
		setWorkplaces,
		error,
		office,
		selectedSpaceId || undefined,
		selectedSpaceId !== MEETING_ROOMS_TAB_ID
	);
	const { config, isAvailable } = useWorkplaceConfiguration({
		office,
		spaceId: selectedSpaceId,
	});

	const deskSize =
		office.id === BookingOfficesId.Minsk ? DeskSize.Small : DeskSize.Large;

	const handleSpacesTabChange = (spaceId: number) => {
		if (areMeetingRoomsSelected || areMeetingRoomsOpen(spaceId)) {
			resetOnMeetingTabChange();
		}

		setSelectedSpaceId(spaceId);
	};

	return (
		<BookingWorkplaceWrapper>
			{isAvailable ? (
				<>
					{hasSpaces && selectedSpaceId && (
						<TitleWrapper>
							<div>
								<SpacesTabs
									hasMeetingRooms={office.location === LocationTypeId.Minsk}
									activeTab={selectedSpaceId}
									onChange={handleSpacesTabChange}
									spaces={office.workspaces}
								/>
							</div>
						</TitleWrapper>
					)}

					{config ? (
						<>
							<WorkplaceSpaceRenderer
								config={config}
								deskSize={deskSize}
								spaceId={selectedSpaceId}
							/>
							<WorkplaceControls showLegend />
						</>
					) : (
						<>
							<MeetingRoomsRenderer />
							<WorkplaceControls showLegend={false} />
						</>
					)}
				</>
			) : (
				<OfficeWarningContainer>
					<OfficeWarningMessageContainer>
						<WarningIcon />
						<OfficeWarningTypograpphy variant="m-400">
							{OFFICE_CLOSED_MESSAGE.firstSentence}
							<br />
							{OFFICE_CLOSED_MESSAGE.secondSentence}
						</OfficeWarningTypograpphy>
					</OfficeWarningMessageContainer>
				</OfficeWarningContainer>
			)}
		</BookingWorkplaceWrapper>
	);
}

export const BookingWorkplace = React.memo(BookingWorkplaceInner);
