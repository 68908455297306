import { MenuItem } from '@mms/mms-ui-library';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { Timeout } from 'react-number-format/types/types';
import { useNavigate } from 'react-router-dom';

import { ProtectedView } from '@/components/ProtectedView';
import { PATH_KEY } from '@/constants/authConstants';
import { Routes } from '@/constants/routes';
import { useAuth } from '@/context/AuthContext';

import { TIMEOUT_DURATION_CLOSED_MENU } from './constants';
import { ItemPayload } from './types';

type PropsTypes = {
	label: string;
	icon: ReactElement | JSX.Element;
	isOpen: boolean;
	selected: boolean;
	onClick: () => void;
	payload: ItemPayload;
	showTooltip: boolean;
	id: number;
};

export function RouteGuard({
	icon,
	label,
	isOpen,
	selected,
	onClick,
	showTooltip,
	id,
	payload: { roles, slug },
}: PropsTypes) {
	const { onLogout } = useAuth();
	const navigate = useNavigate();

	const timeoutRef = useRef<Timeout | null>(null);

	const handleClick = useCallback(() => {
		onClick();

		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(
			() => {
				if (slug === Routes.signOut) {
					onLogout();
				} else {
					localStorage.setItem(PATH_KEY, slug);
					navigate(slug);
				}
			},
			isOpen ? TIMEOUT_DURATION_CLOSED_MENU : 0
		);
	}, [slug, navigate, isOpen]);

	useEffect(
		() => () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		},
		[]
	);

	return (
		<ProtectedView key={slug} roles={roles}>
			<MenuItem
				id={id}
				isOpen={isOpen}
				icon={icon}
				selected={selected}
				isShowTooltip={showTooltip}
				label={label}
				onClick={handleClick}
			/>
		</ProtectedView>
	);
}
