import moment, { Moment } from 'moment';

import {
	NOT_WORKING_DAY_END_HOUR,
	NOT_WORKING_DAY_START_HOUR,
	TIME_SELECTOR_STEP_IN_MINUTES,
	WORKING_DAY_END_HOUR,
	WORKING_DAY_START_HOUR,
} from '../constants';

import { SelectedTime } from '@/context/types';
import { AutoselectOption } from '@/types/common';

import { getFormattedTime } from './bookingFormatters';
import { parseStringToMoment } from './bookingParsers';

const MINUTES_IN_HOUR = 60;
const MS_IN_SEC = 1000;
const HOURS_IN_DAY = 24;

export const getDayStartHour = (isWorkingHours: boolean) =>
	isWorkingHours ? WORKING_DAY_START_HOUR : NOT_WORKING_DAY_START_HOUR;
export const getDayEndHour = (isWorkingHours: boolean) =>
	isWorkingHours ? WORKING_DAY_END_HOUR : NOT_WORKING_DAY_END_HOUR;

export function getNearestQuarterHourTime(
	date = new Date(),
	stepCountOffset = 0,
	skipIfMultiple = false
) {
	const dateMinutes = date.getMinutes();
	const dateHours = date.getHours();

	const hasRest = dateMinutes % TIME_SELECTOR_STEP_IN_MINUTES;
	const extraMinutes = !hasRest && skipIfMultiple ? 1 : 0;

	const nearestMinutes =
		Math.ceil((dateMinutes + extraMinutes) / TIME_SELECTOR_STEP_IN_MINUTES) *
			TIME_SELECTOR_STEP_IN_MINUTES +
		TIME_SELECTOR_STEP_IN_MINUTES * stepCountOffset;

	const resultMinutes = nearestMinutes % MINUTES_IN_HOUR;
	const resultHours =
		(dateHours + Math.floor(nearestMinutes / MINUTES_IN_HOUR)) % HOURS_IN_DAY;

	return getFormattedTime(resultHours, resultMinutes);
}

export const generateTimeOptions = (startHour: number, endHour: number) => {
	const options: AutoselectOption<string>[] = [];
	const possibleMinutes = [];

	for (let i = 0; i < MINUTES_IN_HOUR / TIME_SELECTOR_STEP_IN_MINUTES; i += 1) {
		possibleMinutes.push(i * TIME_SELECTOR_STEP_IN_MINUTES);
	}

	for (let i = startHour; i <= endHour; i += 1) {
		possibleMinutes.forEach((minute) => {
			const formattedTime = getFormattedTime(i, minute);

			options.push({
				id: formattedTime,
				name: formattedTime,
			});
		});
	}

	return options;
};

export const getTimeRange = (
	fromTime: Moment | null | undefined,
	toTime: Moment | null | undefined
): [Moment, Moment] => {
	const parsedFromTime =
		fromTime || parseStringToMoment(getNearestQuarterHourTime());
	const parsedToTime =
		toTime || parseStringToMoment(getNearestQuarterHourTime());

	return [
		parsedFromTime.seconds(0).milliseconds(0),
		parsedToTime.seconds(0).milliseconds(0),
	];
};

export const calculateTimeDifferenceInMinutes = (
	timeFrom: Moment | null | undefined,
	timeTo: Moment | null | undefined
) => {
	if (!timeFrom || !timeTo) {
		return 0;
	}

	const [dateFrom, dateTo] = getTimeRange(timeFrom, timeTo);

	const differenceMs = dateTo.toDate().getTime() - dateFrom.toDate().getTime();

	const minutes = Math.floor(differenceMs / (MS_IN_SEC * MINUTES_IN_HOUR));

	return minutes;
};

export const generateToTimeOptions = (isWorkingHours: boolean) => {
	const startHour = getDayStartHour(isWorkingHours);
	const endHour = getDayEndHour(isWorkingHours);

	const options = generateTimeOptions(startHour, endHour);

	options.shift();

	const lastOptionName = getFormattedTime((endHour + 1) % HOURS_IN_DAY, 0);

	options.push({ id: lastOptionName, name: lastOptionName });

	return options;
};

export const generateFromTimeOptions = (isWorkingHours: boolean) => {
	const startHour = getDayStartHour(isWorkingHours);
	const endHour = getDayEndHour(isWorkingHours);

	const options = generateTimeOptions(startHour, endHour);

	return options;
};

export const getInitialFromTime = (areWorkingHours: boolean) => {
	const initialTime = getNearestQuarterHourTime(undefined, undefined, true);
	const startOfDay = getFormattedTime(getDayStartHour(areWorkingHours), 0);

	const endOfDay = getFormattedTime(
		getDayEndHour(areWorkingHours) + (areWorkingHours ? 0 : 1),
		areWorkingHours ? MINUTES_IN_HOUR - TIME_SELECTOR_STEP_IN_MINUTES : 0
	);

	return initialTime >= startOfDay && initialTime <= endOfDay
		? initialTime
		: startOfDay;
};

export const getInitialTime = (areWorkingHours: boolean): SelectedTime => {
	const initialFromTime = parseStringToMoment(
		getInitialFromTime(areWorkingHours)
	);

	return {
		from: initialFromTime,
		to: parseStringToMoment(
			getNearestQuarterHourTime(initialFromTime.toDate(), 0, true)
		),
	};
};

export const getInitialTimelineDate = (areWorkingHours: boolean) => {
	if (!areWorkingHours) {
		return moment().startOf('day');
	}

	const nextTime = getInitialFromTime(areWorkingHours);
	const currentTime = getNearestQuarterHourTime(undefined, undefined, true);

	return nextTime < currentTime || currentTime === getFormattedTime(0, 0)
		? moment().add(1, 'day').startOf('day')
		: moment().startOf('day');
};
