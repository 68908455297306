import { styled } from '@mui/material';

import { COLOR_MAP } from '@/pages/Booking/constants';

import { SquareProps } from './types';

const SQUARE_SIDE = 21;

export const Square = styled('div')<SquareProps>(
	({ theme, type, color, side }) => ({
		width: side || SQUARE_SIDE,
		height: side || SQUARE_SIDE,
		borderRadius: 2,
		backgroundColor:
			color ||
			theme.palette[
				COLOR_MAP[type as keyof typeof COLOR_MAP] as keyof typeof theme.palette
			],
		cursor: 'pointer',
	})
);
