import { PropsWithChildren } from 'react';

import { SelectedDesk } from '@/context/types';

export interface DeskType {
	workplaceNumber: number;
	department?: string | null;
	firstName?: string;
	isBlocked?: boolean;
	lastName?: string;
	userId?: number;
	workplaceId?: number;
	isSelected?: boolean;
}

export enum DeskDirectionRotateDegree {
	Left = 180,
	Bottom = 90,
	Right = 0,
	Top = 270,
}

export interface Position {
	top: number;
	left: number;
}

export interface OfficeDeskPosition extends Position {
	rotationDegree: number;
	id: number;
	displayNumber: number;
}
export type Wall = Position;

export interface WorkSpaceConfiguration {
	SpaceLayout: (props: PropsWithChildren & { id?: string }) => React.ReactNode;
	seats: Array<OfficeDeskPosition>;
	walls: Array<Wall>;
	spaceAbriviation: string;
}

export type DeskInfo = Pick<SelectedDesk, 'displayNumber' | 'id'>;

export interface SeatGenerationConfig {
	func: (
		padding: number,
		displayNumber: number,
		id: number
	) => OfficeDeskPosition;
	paddings: number[];
}
