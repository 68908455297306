import styled from '@emotion/styled';

import { CELL_HEIGHT } from '../constants';

import { SELECTION_BOX_Z_INDEX, SELECTION_COLORS } from './constants';

export const SelectionBoxWrapper = styled('div')<{
	left: number;
	width: number;
	isOverlaped: boolean;
	sectionId: number;
	isOutOfLimit: boolean;
}>(({ theme, left, width, isOverlaped, sectionId, isOutOfLimit }) => ({
	position: 'absolute',
	top: (sectionId - 1) * CELL_HEIGHT,

	height: CELL_HEIGHT,
	backgroundColor: isOverlaped
		? SELECTION_COLORS['danger-bg']
		: SELECTION_COLORS['success-bg'],
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: isOverlaped
		? theme.palette['feedback-danger']
		: theme.palette['feedback-success'],
	borderRightWidth: isOutOfLimit ? 0 : 1,
	zIndex: SELECTION_BOX_Z_INDEX,
	left,
	width,
	cursor: 'pointer',
	pointerEvents: 'none',
}));
