import styled from '@emotion/styled';

import { TIME_LABELS_HEIGHT } from '../../constants';

const TIME_LABELS_GAP = 77.5;

export const TimeLabelsWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	height: TIME_LABELS_HEIGHT,
	paddingLeft: theme.spaces.xs,
	gap: TIME_LABELS_GAP,
}));
