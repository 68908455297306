import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { GET_CONFIGURATION } from '@/constants/queryPaths';
import { Configuration } from '@/types/Configuration';

export const useGetConfiguration = (options: UseQueryOptions<Configuration>) =>
	useQuery<Configuration>({
		queryKey: ['configuration'],
		...options,
		queryFn: () => axios.get(GET_CONFIGURATION).then((res) => res.data),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
