import moment, { Moment } from 'moment';

import {
	DEFAULT_MINSK_SPACE_ABRIVIATION,
	MINSK_SPACE_ABRIVIATIONS,
	MinskSpaceAbriviations,
	WARSAW_SPACE_ABRIVIATION,
} from '@/constants/booking';
import {
	DESK_UNBLOCKED,
	SUCCESS_BOOKING_CANCELLED,
	BOOKING_CANCELED,
} from '@/constants/notifications';
import { HighlightedDays } from '@/context/types';
import { getTimeAsResponseType } from '@/helpers/booking';
import { parseTimeFromString } from '@/helpers/bookingParsers';
import { isMeetingRoom } from '@/helpers/isMeetingRoom';
import { GetBookingResponse } from '@/queries/booking/types';
import { DeskBooking, MeetingRoomBooking } from '@/types/Booking';

import {
	getDateRange,
	getRepetitiveWeekDays,
} from './components/BookingList/utils';
import { MEETING_ROOMS_TAB_ID } from './components/BookingWorkplace/constants';
import { WORKPLACE_RANGES } from './constants';
import { BookingOfficesId } from './types';

export const getBlockedMonthDays = () =>
	new Array(31).fill(0).map((_, index) => index + 1);

const getParsedBookingItem = (item: DeskBooking | MeetingRoomBooking) => {
	if (isMeetingRoom(item)) {
		const { dateList, weeksInInterval, userId } = item;

		return {
			dateList,
			weeksInInterval,
			isBlock: false,
			userId,
		};
	}

	return item;
};

export const extractHighlightedDaysFromResponse = (
	data: GetBookingResponse,
	id: number
) => {
	const deskAndMeetingRoomsBooking = [
		...data.bookings,
		...data.meetingRoomsWithEntries,
	];
	const days = deskAndMeetingRoomsBooking.reduce(
		(acc: HighlightedDays, item) => {
			const { dateList, isBlock, userId, weeksInInterval } =
				getParsedBookingItem(item);
			const momentDateList = dateList.map((date) => moment(date));

			if (userId === id) {
				if (weeksInInterval > 0) {
					const repetitiveWeekDays = getRepetitiveWeekDays(
						momentDateList,
						weeksInInterval
					);
					acc.yourList?.push(...repetitiveWeekDays);

					return acc;
				}

				if (momentDateList.length === 1) {
					acc.yourList?.push(...momentDateList);

					return acc;
				}

				const [rangeStart, rangeEnd] = momentDateList;

				if (isBlock) {
					acc.blockStartDate = rangeStart;
				} else {
					const dateRange = getDateRange(rangeStart, rangeEnd);

					acc.yourList?.push(...dateRange);
				}

				return acc;
			}

			acc.bookedList?.push(...momentDateList);

			return acc;
		},
		{
			bookedList: [],
			blockStartDate: null,
			yourList: [],
		}
	);

	return days;
};

export const getSpaceAbriviation = (
	officeId: string,
	spaceId: number | null
) => {
	if (officeId === BookingOfficesId.Minsk) {
		return spaceId
			? MINSK_SPACE_ABRIVIATIONS[spaceId] || DEFAULT_MINSK_SPACE_ABRIVIATION
			: DEFAULT_MINSK_SPACE_ABRIVIATION;
	}

	return WARSAW_SPACE_ABRIVIATION;
};

const isInRange = (number: number, range: [number, number]) =>
	number >= range[0] && number <= range[1];

export const getSpaceAbriviationBySeatId = (seatId: number) => {
	const { minskAIds, minskBIds, minskCIds, minskDIds } = WORKPLACE_RANGES;

	if (isInRange(seatId, minskAIds)) {
		return MinskSpaceAbriviations.A;
	}

	if (isInRange(seatId, minskBIds)) {
		return MinskSpaceAbriviations.B;
	}

	if (isInRange(seatId, minskCIds)) {
		return MinskSpaceAbriviations.C;
	}

	if (isInRange(seatId, minskDIds)) {
		return MinskSpaceAbriviations.D;
	}

	return WARSAW_SPACE_ABRIVIATION;
};

export const getDeskName = (abriviation: string, deskNumber: number) =>
	`${abriviation}-${deskNumber}`;

export const areMeetingRoomsOpen = (tabId: number | null) =>
	tabId === MEETING_ROOMS_TAB_ID;

export const isZeroTime = (time: Moment) =>
	time.hour() === 0 && time.minute() === 0;

export const getConvertedBookingTime = (
	time: Moment | null | undefined,
	isAllDayChecked: boolean,
	zeroDateAlternative: string
): string | undefined => {
	if (!time) {
		return undefined;
	}

	return isAllDayChecked || isZeroTime(time)
		? zeroDateAlternative
		: getTimeAsResponseType(time);
};

export const getTimeRangeFromString = (from: string, to: string) => {
	const startDate = parseTimeFromString(from);
	const endDate = parseTimeFromString(to, from >= to);

	return [startDate, endDate];
};

export const parseTimeFromMoment = (time: Moment, addOneDay = false) => {
	const now = time.toDate();
	now.setSeconds(0, 0);

	if (addOneDay) {
		now.setDate(now.getDate() + 1);
	}

	return now;
};

export const getBookingDeletingToastMessage = (
	isBlock: boolean,
	isOwnBooking: boolean
) => {
	if (isBlock) {
		return DESK_UNBLOCKED;
	}

	if (isOwnBooking) {
		return SUCCESS_BOOKING_CANCELLED;
	}

	return BOOKING_CANCELED;
};
