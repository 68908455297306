import styled from '@emotion/styled';

import { TIME_LABELS_HEIGHT, TABLE_HEADER_HEIGHT } from '../constants';

import { BorderRadiuses } from '@/constants/borderRadiuses';

const TIMELINE_SECTIONS_COLUMN_MIN_WIDTH = 124;

export const TimelineSectionsColumnWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: TIME_LABELS_HEIGHT,
	minWidth: TIMELINE_SECTIONS_COLUMN_MIN_WIDTH,
	border: `1px solid ${theme.palette['primary-4']}`,
	borderRadius: `${BorderRadiuses.xs2}px 0 0 ${BorderRadiuses.xs2}px`,
	borderRight: 0,
}));

export const TimelineSectionsColumnTitleWrapper = styled('div')(
	({ theme }) => ({
		height: TABLE_HEADER_HEIGHT,
		borderBottom: `1px solid ${theme.palette['primary-4']}`,
		paddingLeft: theme.spaces.s,
	})
);
