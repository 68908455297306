import { Button, Tooltip } from '@mms/mms-ui-library';
import { isEqual } from 'lodash';
import { PropsWithChildren, ReactElement, memo } from 'react';

import type { BookButtonInnerProps } from './types';

function BookButtonInner({
	children,
	tooltipTitle,
	onClick,
	disabled,
	color,
}: PropsWithChildren<BookButtonInnerProps>) {
	const buttonProps = {
		onClick,
		disabled,
		color,
	};

	return (
		<Tooltip open={Boolean(tooltipTitle) && disabled} text={tooltipTitle}>
			<Button {...buttonProps} size="medium" noWrap>
				{children as ReactElement}
			</Button>
		</Tooltip>
	);
}

export const BookButton = memo(BookButtonInner, isEqual);
