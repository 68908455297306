import { styled } from '@mui/material';

import { BOOKING_SMALL_MOBILE_SCREEN_WIDTH } from '../../constants';

export const BookingFormWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: theme.spaces.m,
	height: '100%',

	[theme.breakpoints.down(BOOKING_SMALL_MOBILE_SCREEN_WIDTH)]: {
		width: '100%',
	},
}));

export const DatePickersWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	gap: theme.spaces.s,
	marginBottom: theme.spaces.m,

	[theme.breakpoints.down(BOOKING_SMALL_MOBILE_SCREEN_WIDTH)]: {
		width: '100%',
	},

	'[data-testid="data-picker-view"] > div': {
		width: '100%',
		minWidth: '100%',
		maxWidth: '100%',
	},
}));
