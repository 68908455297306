import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { GET_USERS_API_ENDPOINT } from '@/constants/queryPaths';
import { getKeywordQuery } from '@/helpers/queries';
import { LookupUserResponse } from '@/types/UserInfo';

interface UseUsersLookupOptions {
	search?: string;
	roles?: Array<string>;
	queryOptions?: UseQueryOptions<Array<LookupUserResponse>, AxiosError>;
	isActive?: boolean;
}

export const useUsersLookup = ({
	search = '',
	roles = [],
	queryOptions,
	isActive,
}: UseUsersLookupOptions) => {
	const query = getKeywordQuery({ search, roles, isActive });

	return useQuery<Array<LookupUserResponse>, AxiosError>({
		...queryOptions,
		queryKey: ['users-lookup', search],
		queryFn: () =>
			axios
				.get<Array<LookupUserResponse>>(
					`${GET_USERS_API_ENDPOINT}/lookup${query}`
				)
				.then((res) => res.data),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
};
