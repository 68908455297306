import { IconPolygon } from '@/assets/logo/IconPolygon';

import { LogoWrapper, SubTitle, Title, TitleWrapper } from './styles';

export function Logo() {
	return (
		<LogoWrapper>
			<TitleWrapper>
				<Title>Modsen</Title>
				<IconPolygon fontSize="small" sx={{ width: 12, height: 12 }} />
			</TitleWrapper>
			<SubTitle>Management System</SubTitle>
		</LogoWrapper>
	);
}
