export const ActivityStatus = {
	Active: 'Active',
	Inactive: 'Inactive',
};

export const ACTIVATE = 'Activate';
export const DEACTIVATE = 'Deactivate';

export const getDeactivatingPopUpTitle = (entityName: string) =>
	`${entityName} deactivating`;

export const getActivatingPopUpTitle = (entityName: string) =>
	`${entityName} activating`;

export const getAddingPopUpTitle = (entityName: string) =>
	`${entityName} adding`;

export const getEditingPopUpTitle = (entityName: string) =>
	`${entityName} editing`;
