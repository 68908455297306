import styled from '@emotion/styled';
import { Typography } from '@mms/mms-ui-library';

import { Row } from '../styles';

import { BorderRadiuses } from '@/constants/borderRadiuses';
import { IconSize } from '@/constants/iconSizes';

import {
	BOOKED_LABEL_HEIGHT,
	BOOKED_TIME_PERIOD_GAP,
	BOOKED_TYPOGRAPHY_FONT_WEIGHT,
	LOOP_ICON_TRANSLATION,
	QUATER_PERIOD_LOOP_ICON_TRANSLATION,
	QUATER_PERIOD_PADDING,
} from './constants';

export const BookedLabelWrapper = styled('div')<{
	posX: number;
	width: number;
	isQuaterPeriod: boolean;
	isDisabled: boolean;
}>(({ theme, posX, width, isQuaterPeriod, isDisabled }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'flex-start',
	position: 'absolute',
	left: posX,
	backgroundColor: theme.palette['primary-3'],
	height: BOOKED_LABEL_HEIGHT,
	padding: isQuaterPeriod ? QUATER_PERIOD_PADDING : theme.spaces.xs,
	marginLeft: theme.spaces.xs,
	width,
	borderLeft: `${BorderRadiuses.xs2}px solid ${theme.palette['primary-5']}`,
	borderRadius: BorderRadiuses.xs2,
	cursor: isDisabled ? 'default' : 'pointer',
}));

export const FixedTypography = styled(Typography)({
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	fontWeight: BOOKED_TYPOGRAPHY_FONT_WEIGHT,
	textWrap: 'nowrap',
	overflow: 'hidden',
});

export const BookedTimePeriod = styled(Row)({
	width: '100%',
	justifyContent: 'space-between',
	alignItems: 'flex-end',
	overflow: 'hidden',
});

export const BookedTimePeriodRow = styled(Row)({
	display: 'flex',
	flex: 1,
	overflow: 'hidden',
	gap: BOOKED_TIME_PERIOD_GAP,
});

export const ClockIconWrapper = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const LoopIconWrapper = styled('div')<{ isQuaterPeriod: boolean }>(
	({ isQuaterPeriod }) => {
		const iconSize = isQuaterPeriod ? IconSize.MS : IconSize.M;
		const translation = isQuaterPeriod
			? LOOP_ICON_TRANSLATION
			: QUATER_PERIOD_LOOP_ICON_TRANSLATION;

		return {
			width: iconSize,
			height: iconSize,
			transform: translation,

			'& > svg': {
				width: iconSize,
				height: iconSize,
			},
		};
	}
);
