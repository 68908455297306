import { Theme } from '@mui/material';

import { DeskInfo, OfficeDeskPosition } from '../../../../types';

import { COLORS_ENUM } from '@/constants/index';
import { WithMobileView } from '@/types/Filter';
import { UserInfo } from '@/types/UserInfo';

export enum DeskSize {
	Small = 'small',
	Large = 'large',
}
export interface DeskInnerProps extends OfficeDeskPosition, WithMobileView {
	firstName?: string;
	lastName?: string;
	userId?: number;
	spaceTitle: string;
	isBlocked?: boolean;
	currentUser?: UserInfo;
	isSelected?: boolean;
	department?: string | null;
	onClick: (deskInfo: DeskInfo | null) => void;
	size: DeskSize;
}

export interface DeskWrapperProps {
	theme?: Theme;
	isBlocked?: boolean;
	isBooked: boolean;
	isBookedByUser: boolean;
	rotationDegree: number;
	isSelected?: boolean;
}

export interface DeskTooltipTextWrapperProps {
	isMobile: boolean;
}

export type ColorType = (typeof COLORS_ENUM)[keyof typeof COLORS_ENUM];
