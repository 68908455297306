import { Chevron, DropTab, Popover } from '@mms/mms-ui-library';
import { RefObject, useRef, useState } from 'react';

import { bookingOfficeNames, officeSelectorOptions } from '@/constants/index';
import { useBookingContext } from '@/context/Booking';
import { useConfiguration } from '@/context/index';
import { useOutsideClickMany } from '@/hooks/useOutsideClickMany';
import { OfficeSelectorOption } from '@/pages/Booking/types';
import { OfficeType } from '@/types/Configuration';

import {
	OfficeName,
	OfficeSelectorChevronWrapper,
	OfficeSelectorWrapper,
} from './styled';

export function OfficeSelector() {
	const {
		configurableValues: { offices },
	} = useConfiguration();
	const { office, initialOffice, setOffice } = useBookingContext();
	const [isOpen, setIsOpen] = useState(false);

	const modalRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const handleToggleIsOpen = () => {
		setIsOpen((prevOpen) => !prevOpen);
	};

	const handleClickOutside = () => {
		setIsOpen(false);
	};

	const changeOfficeLocation = (option: OfficeSelectorOption) => {
		const foundOffice = offices?.find(
			(off) => off.location === option.officeLocation
		);

		if (foundOffice) {
			setOffice(foundOffice);
		}
		setIsOpen(false);
	};

	useOutsideClickMany(
		[modalRef, containerRef as RefObject<HTMLElement>],
		handleClickOutside
	);

	const officeNameToRender = bookingOfficeNames[office.location];

	const value: OfficeType = office ?? initialOffice;

	const valueForDroptab: OfficeSelectorOption = {
		name: bookingOfficeNames[value.location],
		officeLocation: value.location,
	};

	return (
		<OfficeSelectorWrapper ref={containerRef} onClick={handleToggleIsOpen}>
			<OfficeName variant="small">{officeNameToRender}</OfficeName>
			<OfficeSelectorChevronWrapper isOpen={isOpen}>
				<Chevron />
			</OfficeSelectorChevronWrapper>
			<Popover
				withTransition={false}
				ref={modalRef}
				anchorElement={containerRef.current}
				distanceToAnchorElement={9}
				open={isOpen}
				position="bottom-end"
			>
				<DropTab
					options={officeSelectorOptions}
					value={valueForDroptab}
					onChange={changeOfficeLocation}
					valueField="name"
					gapBetweenItems={0}
					width="200px"
				/>
			</Popover>
		</OfficeSelectorWrapper>
	);
}
