import moment from 'moment';

export const parseStringToMoment = (timeStr: string) =>
	moment(timeStr, 'HH:mm');

export const parseTimeFromString = (timeStr: string, addOneDay = false) => {
	const [hours, minutes] = timeStr.split(':').map(Number);
	const now = new Date();
	now.setHours(hours, minutes, 0, 0);

	if (addOneDay) {
		now.setDate(now.getDate() + 1);
	}

	return moment(now);
};
