import { Theme, useTheme } from '@emotion/react';
import { PopUp, ThemeMode } from '@mms/mms-ui-library';
import { useMemo } from 'react';

import { useBookingMapContext } from '../../context/BookingMapContext';

import { zIndexes } from '@/constants/zIndexes';

import { OFFICE_MAP_POPUP_MAX_WIDTH, POPUP_TITLE } from './constants';
import darkThemeMapImage from './DarkThemeMapImage.svg';
import mapImage from './MapImage.svg';
import { MapImageContianer } from './styled';

const getPopUpSize = (theme: Theme) => `calc(100% - ${theme.spaces.m * 2}px)`;

export function MapPopUp() {
	const { setIsMapOpen } = useBookingMapContext();

	const handleMapClose = () => {
		setIsMapOpen(false);
	};
	const theme = useTheme();

	const popUpStylesConfig = useMemo(
		() => ({
			container: {
				maxHeight: getPopUpSize(theme),
				height: 'min-content',
				width: getPopUpSize(theme),
				maxWidth: OFFICE_MAP_POPUP_MAX_WIDTH,
				backgroundColor: theme.palette['primary-2'],
				overflow: 'hidden',

				display: 'flex',
				flexDirection: 'column',

				'& > header': {
					flexShrink: 0,
				},

				'& > div': {
					flexGrow: 1,
					minHeight: 0,
					display: 'flex',
				},
			},
			content: {
				padding: `${theme.spaces.l}px ${theme.spaces.xl2}px`,
				width: '100%',
				display: 'flex',
				flexGrow: 1,
				minHeight: 0,
				height: 'auto',
			},
		}),
		[theme]
	);

	return (
		<PopUp
			title={POPUP_TITLE}
			onClose={handleMapClose}
			stylesConfig={popUpStylesConfig as any}
			type="info"
			zIndex={zIndexes.header + 1}
			hasPaddings={false}
		>
			<MapImageContianer>
				<img
					src={theme.mode === ThemeMode.DARK ? darkThemeMapImage : mapImage}
					alt={POPUP_TITLE}
				/>
			</MapImageContianer>
		</PopUp>
	);
}
